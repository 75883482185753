import React, { CSSProperties, useCallback, useEffect } from "react";
import { Checkbox, FormControlLabel, InputAdornment, MenuItem, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/close-circle.svg";
import Joi from "joi";
import FormHelperText from "@material-ui/core/FormHelperText";
import { get } from "lodash";
import IMask from "imask";
import { mapFieldErrorsMessage } from "./mapFieldErrorsMessage";

export function FieldLabel(props: { label: any; style?: CSSProperties }) {
  if (!props.label) return null;

  return (
    <div
      className="fieldLabel"
      style={{
        fontWeight: 500,
        marginBottom: "5px",
        fontSize: "14px",
        ...props.style,
      }}
    >
      {props.label}
    </div>
  );
}

function _Wrap({ children, label }) {
  return (
    <>
      <FieldLabel label={label} />
      {children}
    </>
  );
}

interface FieldProps {
  containerClassName?: string;
  name: string;
  validate?: boolean;
  validationResult?: Joi.ValidationResult;
  className?: string;
  type?: "text" | "email" | "checkbox" | "number" | "password" | "radio" | "select" | "search" | "textarea";
  label?: any;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  options?: any[];
  selectValueToDisplay: (value: any) => string;
  placeholder?: string;
  onChange?: (name, value) => void;
  variant?: "outlined" | "filled";
  leftIcon?: any;
  rightIcon?: any;
  min?: number;
  max?: number;
  defaultValue?: any;
  rows?: number;
  formState?: any;
  InputProps?: any;
  children?: any;
  checkbodNoLeftMargin?: boolean;
  mask?: IMask.AnyMaskedOptions;
  unmaskedResult?: boolean;
  preventErrMsg?: boolean;
}

function searchField(props) {
  const internalOnChange = () => {
    props.onChange({ name: props.name, target: { value: "" } });
  };

  return (
    <TextField
      {...props}
      type="text"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end" className="l-clickable" onClick={internalOnChange}>
            <CloseIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}

export function CustomField(props: FieldProps) {
  const formState = props.formState || {};
  const internalDefaultValue = props.type === "checkbox" ? false : "";
  const defaultValue = props.defaultValue !== undefined ? props.defaultValue : internalDefaultValue;
  const options = props.options || [];
  const selectValueToDisplay = props.selectValueToDisplay;
  const children = props.children;
  const attrName = props.name;
  const rawValue = get(formState, attrName) || defaultValue;
  const parentOnChange = props.onChange;

  const validationResult = props.validationResult;
  //@ts-ignore
  const errorDetails = validationResult && validationResult.error?.details;
  const validation =
    validationResult &&
    errorDetails?.find((it) => {
      let pathParts: Array<string | number> = [];

      if (Array.isArray(it.path)) {
        pathParts =
          it.path?.map((p) => {
            return typeof p === "number" ? `[${p}]` : `.${p}`;
          }) || [];
      }

      let builtPath = pathParts.join("").replace(/^\./, "");

      return builtPath.indexOf(attrName) > -1;
    });

  const validationMessage = props.validate && (validation as Joi.ValidationErrorItem)?.message;

  const masked = useCallback(() => {
    //@ts-ignore
    return props.mask && IMask.createMask(props.mask);
  }, [])();

  if (masked) {
    masked.resolve(JSON.stringify(rawValue));
  }

  const value = masked ? masked.value : rawValue;

  useEffect(() => {
    console.log(`<<FIELD>> mounted field ${attrName}`);
  }, []);

  const defaultInternalOnChange = (e) => {
    if (!parentOnChange) return;

    let rawResult = props.type === "checkbox" ? e.target.checked : e.target.value;
    let result: any;

    if (masked && !props.unmaskedResult) {
      masked.resolve(rawResult);
      result = masked.unmaskedValue;
    } else {
      result = rawResult;
    }

    parentOnChange(attrName, result);
  };

  let FieldType = TextField as React.JSXElementConstructor<any>;
  let Wrap = _Wrap;

  switch (props.type) {
    case "checkbox":
      FieldType = ({ error, helperText, value, ...props }) => {
        const checkGroupOnChange = (index, e) => {
          const arrayPath = attrName + `[${index}]`;
          const value = e.target.checked ? options[index] : null;
          parentOnChange && parentOnChange(arrayPath, value);
        };

        return options.length === 0 ? (
          <Checkbox {...props} checked={value} disableRipple />
        ) : (
          <RadioGroup>
            {children ||
              options.map((it, index) => (
                <FormControlLabel
                  style={{ margin: "10px 0" }}
                  control={<Checkbox checked={(rawValue || []).indexOf(it) > -1} />}
                  label={it}
                  value={it}
                  onChange={(e) => checkGroupOnChange(index, e)}
                />
              ))}
          </RadioGroup>
        );
      };

      Wrap = ({ children }) => (
        <div>
          <div className="l-flex-align-center">
            <div style={props.checkbodNoLeftMargin ? { marginLeft: "-10px" } : undefined}>{children}</div>
            {props.label}
          </div>
          <FormHelperText error>{mapFieldErrorsMessage(validationMessage)}</FormHelperText>
        </div>
      );
      break;

    case "radio":
      FieldType = ({ error, helperText, value, ...props }) => (
        <>
          <RadioGroup {...props}>
            {children || options.map((it) => <FormControlLabel control={<Radio checked={rawValue === it} />} label={it} value={it} />)}
          </RadioGroup>
        </>
      );

      Wrap = ({ children }) => (
        <div>
          <FieldLabel label={props.label} />
          {children}
          <FormHelperText error>{mapFieldErrorsMessage(validationMessage)}</FormHelperText>
        </div>
      );
      break;

    case "search":
      FieldType = searchField;
      break;

    case "select":
      FieldType = (props: FieldProps) => {
        const findSelectedOption = options.find((v) => v.id == value);
        const selectedOption = findSelectedOption ? findSelectedOption.id : "";

        return (
          <div>
            <Select {...props} value={selectedOption} displayEmpty={Boolean(props.placeholder)}>
              {options.map((it) => (
                <MenuItem key={it.id} value={it.id}>
                  {selectValueToDisplay(it)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText error>{mapFieldErrorsMessage(validationMessage)}</FormHelperText>
          </div>
        );
      };
      break;
  }

  let inputProps = { inputProps: {} } as any;

  if (props.leftIcon) {
    inputProps.startAdornment = <InputAdornment position="start">{props.leftIcon}</InputAdornment>;
  }

  if (props.min !== undefined || props.max !== undefined) {
    inputProps.inputProps.min = props.min;
    inputProps.inputProps.max = props.max;
  }

  if (props.type === "password") {
    inputProps.inputProps.type = props.type;
  }

  if (props.rightIcon) {
    inputProps.endAdornment = (
      <InputAdornment position="end" className="l-clickable" onClick={defaultInternalOnChange}>
        {props.rightIcon}
      </InputAdornment>
    );
  }

  const nextInputProps = { ...inputProps, ...props.InputProps };

  const condProps = {} as any;

  if (Object.keys(nextInputProps).length) {
    condProps.InputProps = nextInputProps;
  }

  if (validationMessage && !props.preventErrMsg) {
    condProps.helperText = mapFieldErrorsMessage(validationMessage);
  }

  const attributeType = props.type === "password" ? "text" : props.type;

  const fieldType = (
    <FieldType
      style={props.style}
      className={props.className}
      placeholder={props.placeholder}
      type={attributeType}
      options={props.options}
      onChange={defaultInternalOnChange}
      rows={props.rows}
      error={Boolean(validationMessage)}
      multiline={props.type === "textarea" ? true : undefined}
      value={value}
      variant={props.variant}
      {...condProps}
    />
  );

  return (
    <div
      className={"field " + props.containerClassName}
      style={{
        marginTop: "20px",
        marginBottom: "20px",
        ...props.containerStyle,
      }}
    >
      <Wrap label={props.label}>{fieldType}</Wrap>
    </div>
  );
}
