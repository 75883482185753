import { _t } from "../translate/translate-wrapper";

export function statusDescription(status): string {
  switch (status) {
    case "adminCancelled":
      return _t("admin_cancelled")
    case "confirmed":
      return _t("confirmed");
    case "notFound":
      return _t("not_found");
    case "pending":
      return _t("pending");
    case "userCancelled":
    case "userCancelledByAdmin":
      return _t("worker_cancelled");
    case "userCancelledLate":
    case "userCancelledLateByAdmin":
      return _t("worker_cancelled_late");
    case "companyCancelled":
    case "companyCancelledByAdmin":
      return _t("you_cancelled");
    case "companyCancelledLate":
    case "companyCancelledLateByAdmin":
      return _t("you_cancelled_late");
    case "confirmedWithCancelledDays":
      return _t("confirmed_but_contains_cancelled_days");
    default:
      return _t("not_found");
  }
}

export function statusColor(status): string {
  switch (status) {
    case "confirmed":
      return "#219653";
    case "notFound":
      return "#A8A8A8";
    case "pending":
      return "#325E8C";
    case "adminCancelled":
      return "#EB5757";
    case "userCancelled":
    case "userCancelledByAdmin":
      return "#EB5757";
    case "userCancelledLate":
    case "userCancelledLateByAdmin":
      return "#EB5757";
    case "companyCancelled":
    case "companyCancelledByAdmin":
      return "#EB5757";
    case "companyCancelledLate":
    case "companyCancelledLateByAdmin":
      return "#EB5757";
    case "confirmedWithCancelledDays":
      return "#F2994A";
    default:
      return "#A8A8A8";
  }
}
