import { _t } from "../../../app/translate/translate-wrapper";

export function mapFieldErrorsMessage(errorMessage) {
  return errorMessage
    ? errorMessage
        .replace("mainTask", _t("main_task"))
        .replace("companyName", _t("company_name"))
        .replace("otherTask", _t("other_task"))
        .replace("otherIndustry", _t("other_industry_type"))
        .replace("liftWeight", _t("lift_weight"))
        .replace("province", _t("province"))
        .replace("city", _t("city"))
        .replace("address", _t("address"))
        .replace("postalCode", _t("postal_code"))
        .replace("requireWarehouseExperience", _t("warehouse_experience"))
        .replace("languages", _t("required_languages"))
        .replace("contactFirstName", _t("contact_first_name"))
        .replace("contactLastName", _t("contact_last_name"))
        .replace("contactEmail", _t("email_address"))
        .replace("name", _t("name"))
        .replace("phoneNumber1", _t("phone_number"))
        .replace("industryType", _t("industry"))
        .replace("size", _t("size"))
        .replace("numberEmployees", _t("number_of_employees"))
        .replace("trainingProcess", _t("training_process"))
        .replace("about", _t("about"))
        .replace("descriptionText", _t("description"))
    : errorMessage;
}
