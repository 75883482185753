import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import React from "react";

export const theme = createMuiTheme({
    palette: {
        primary: {
            light: "#cccccc",
            main: "#b5b5b5",
            dark: "#9e9e9e",
            contrastText: "#fff",
        },
        secondary: {
            light: "#808080",
            main: "#666666",
            dark: "#4d4d4d",
            contrastText: "#fff",
        }
    },
    typography: {
        fontFamily: '"Circular Std Book", sans-serif'
    }
});

export function WhiteTheme({children}) {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

export function DarkTheme({children}) {
    return (
        <ThemeProvider theme={{...theme, palette: {...theme.palette, type: "dark"}}}>
            {children}
        </ThemeProvider>
    )
}