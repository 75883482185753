import {Model} from "./_model";
import Parse from "parse";

export class CompanyModel extends Model {

    constructor() {
        super("Company");
    }

}

Parse.Object.registerSubclass("Company", CompanyModel);