import { Store } from "pullstate";
import { UserModel } from "../models/user-model";
import Parse from "parse";
import { JobModel } from "../models/job-model";
import { ForkliftModel } from "../models/forklift-model";

export type Dictionary<T> = {
  [x: string]: T;
};

export function generateInitialState() {
  return {
    auth: Parse.User.current() as UserModel | undefined,
    jobsUpcoming: {} as Dictionary<JobModel>,
    jobsHistory: {} as Dictionary<JobModel>,
    jobsAll: {} as Dictionary<JobModel>,
    forklifts: {} as Dictionary<ForkliftModel>,
    resetEmail: "",
    ui: {
      header: {
        visible: true,
      },
    },
  };
}

export const globalStore = new Store(generateInitialState());

