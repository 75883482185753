import React from "react";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { Field } from "../components/controls/field";
import { Link } from "react-router-dom";
import { routes } from "../../app/routes";
import { Button } from "../components/controls/button";
import warehouseImg from "../../assets/images/Warehouse management system 2 1.png";
import { useHiddenHeader } from "../../app/services/ui-service";
import { inlineErr } from "../../app/utils/error-handler-util";
import { signUp } from "../../app/services/user-service";
import { useNavigate } from "react-router";
import { Form, useForms } from "../components/controls/form";
import { toast } from "react-toastify";
import Joi from "joi";
import { getSaveLanguagePreference, saveLanguagePreference, _t } from "../../app/translate/translate-wrapper";

const schema = Joi.object({
  email: Joi.string().email({ tlds: false }).required().label("Email"),
  password: Joi.string().min(4).required().label("Password"),
  agreement: Joi.boolean().valid(true).label("Agreement").required(),
});

export default function SignUpPage() {
  const navigate = useNavigate();

  const { genericFieldProps, onSubmit } = useForms(schema, {}, async (formIsValid, formState) => {
    if (formIsValid) {
      const [_, signUpError] = await inlineErr(signUp(formState));

      if (signUpError) {
        if ((signUpError as any).code === 202) {
          return toast.error(_t("this_email_is_already_registered"));
        }

        toast.error(_t("something_wrong_happened"));
      } else {
        navigate(routes.aboutCompany);
      }
    }
  });

  useHiddenHeader();

  return (
    <>
      <div className="l-flex-between" style={{ overflow: "hidden" }}>
        <Form
          style={{
            height: "100vh",
            flexGrow: 1,
            alignSelf: "start",
            overflow: "auto",
            position: "relative",
            zIndex: 1,
          }}
          onSubmit={onSubmit}
        >
          <div className="l-content" style={{ maxWidth: "475px" }}>
            <div style={{ marginTop: "62px" }}>
              <Link to={routes.root}>
                <LogoIcon />
              </Link>

              <a
                onClick={() => saveLanguagePreference()}
                className="blue-menu-item l-clickable"
                style={{ float: "right", marginTop: "5%" }}
              >
                {getSaveLanguagePreference() == "en" ? "Français" : "English"}
              </a>

              <h1
                className="l-text-alternate-family"
                style={{
                  marginTop: "50px",
                  marginBottom: "30px",
                  lineHeight: "45px",
                }}
              >
                {_t("create_your_company_account")}
              </h1>
            </div>

            <hr style={{ margin: "30px 0" }} />

            <Field className="l-full-width" name="email" label={_t("email_address")} variant="outlined" {...genericFieldProps} />
            <Field
              className="l-full-width"
              name="password"
              type="password"
              label={_t("password")}
              variant="outlined"
              {...genericFieldProps}
            />

            <div>
              <Field
                name="agreement"
                checkbodNoLeftMargin
                type="checkbox"
                {...genericFieldProps}
                label={
                  <div style={{ display: "inline-block", paddingTop: "15px" }}>
                    {_t("by_clicking_on_sign_up")}{" "}
                    <Link to={routes.terms} target="_blank" style={{ color: "#0F2F60" }}>
                      <strong>{_t("terms_and_conditions")}</strong>
                    </Link>{" "}
                    {_t("and")}{" "}
                    <Link to={routes.privacy} target="_blank" style={{ color: "#0F2F60" }}>
                      <strong>{_t("privacy_policy")}</strong>
                    </Link>
                  </div>
                }
              />
            </div>

            <Button className="l-full-width" valid={true} style={{ marginTop: "43px" }}>
              {_t("sign_up")}
            </Button>
            <div className="l-inline-center" style={{ marginTop: "20px", color: "#666666", paddingBottom: "30px" }}>
              {_t("already_have_an_account_question")}{" "}
              <Link to={routes.signin} style={{ color: "#28588C" }}>
                <strong> {_t("log_in")}</strong>
              </Link>
            </div>
          </div>
        </Form>

        <div
          className="l-flex-center l-mob-hide"
          style={{
            background: "#28588C",
            height: "100vh",
            zIndex: 2,
            position: "relative",
            flexShrink: 1,
          }}
        >
          <img src={warehouseImg} style={{ position: "relative", left: "-75px" }} />
        </div>
      </div>
    </>
  );
}
