import { CompanyModel } from "../models/company-model";
import { UserModel } from "../models/user-model";
import Parse from "parse";
import { globalStore } from "../state/store";
import { _t } from "../translate/translate-wrapper";

export async function saveCompany(user: UserModel, data: any) {
  let company: any;

  if (user.company) {
    const companyId = user.company.id;
    const query = new Parse.Query(CompanyModel);
    query.equalTo("objectId", companyId);
    company = await query.first();
  } else {
    company = new CompanyModel();
  }

  company.set("contactFirstName", data.contactFirstName);
  company.set("contactLastName", data.contactLastName);
  company.set("contactEmail", data.contactEmail);
  company.set("name", data.companyName);
  company.set("websiteLink", data.websiteLink);
  company.set("phoneNumber1", data.phoneNumber1);
  company.set("phoneNumber2", data.phoneNumber2);
  company.set("industryType", data.otherIndustry && data.industryType == _t("other") ? data.otherIndustry : data.industryType);
  company.set("size", data.size);
  company.set("numberEmployees", data.numberEmployees);
  company.set("trainingProcess", data.trainingProcess);
  company.set("trainingProcessFR", data.trainingProcessFR);
  company.set("about", data.about);
  company.set("aboutFR", data.aboutFR);
  company.set("image", data.image);
  company.set("isCompleted", true);
  await company.save();

  user.set("company", company);
  await user.save();

  globalStore.update((s) => {
    s.auth = user.newInstance();
  });
}
