import React, { useEffect, useState } from "react";
import { ReactComponent as LogoIcon } from "../../../assets/icons/logo.svg";
import { ReactComponent as DrawerIcon } from "../../../assets/icons/drawer.svg";
import { routes } from "../../../app/routes";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { generatePath } from "react-router";
// import {logout as _logout, useCurrentUser} from "../../../app/services/user-service";
import { Modal, useModal } from "../controls/modal";
import { useHeaderState } from "../../../app/services/ui-service";
import { Button } from "../controls/button";
import { logout, useCurrentUser } from "../../../app/services/user-service";
import { ContextMenu } from "../context-menu";
import Rating from "@material-ui/lab/Rating";
import { Field } from "../controls/field";
import Joi from "joi";
import { useForms } from "../controls/form";
import { createIssue, createSystemReview, hasCompletedNotReviewedJobs, getSystemReview } from "../../../app/services/job-service";
import { toast } from "react-toastify";
import { getSaveLanguagePreference, saveLanguagePreference, _t } from "../../../app/translate/translate-wrapper";
import { JobModel } from "../../../app/models/job-model";
import ReactStars from "react-rating-stars-component";

export function Header() {
  const [rating, setRating] = useState(0 as any);

  const uiHeader = useHeaderState();
  const [isWhite, setIsWhite] = useState(false);
  const [toggleDrawer, _setToggleDrawer] = useState(false);
  const [searchParams] = useSearchParams();
  const { pathname } = useLocation();
  const currentUser = useCurrentUser();
  const navigate = useNavigate();

  const logoutModal = useModal();
  const ratingModal = useModal();
  const reportModal = useModal();

  let company = currentUser?.company;

  useEffect(() => {
    getSystemRating();
  }, []);

  useEffect(() => {
    let listener = (e: Event) => {
      if (window.scrollY <= 25) {
        setIsWhite(false);
      } else {
        !isWhite && setIsWhite(true);
      }
    };

    window.addEventListener("scroll", listener, { passive: true });
  }, []);

  const getSystemRating = async () => {
    const review = await getSystemReview(company);

    if (review) {
      setRating(review.attributes.rating);

      ratingForm.formState.descriptionText = review.attributes.descriptionText;
      ratingForm.formState.currentRatingObject = review;

      ratingForm.setFormValue({
        ...ratingForm.formState,
      });
    }
  };

  const isAuthPage = searchParams.get("signup") || searchParams.get("login");

  const setToggleDrawer = (toggle: boolean) => {
    if (toggle) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    _setToggleDrawer(toggle);
  };

  const isHome = pathname === routes.root;

  const _logout = async () => {
    logoutModal.close();

    localStorage.removeItem("notifiedCompletedJobs");

    await logout();
  };

  const ratingSchema = Joi.object({
    descriptionText: Joi.any().required(),
    currentRatingObject: Joi.any().optional(),
  });

  const ratingForm = useForms(ratingSchema, {}, async (formIsValid, formState) => {
    if (formIsValid) {
      await createSystemReview(company, rating, formState.descriptionText, formState.currentRatingObject);

      ratingModal.close();

      toast.success(_t("rating_sent"));
    }

    ratingModal.close();
  });

  const issueSchema = Joi.object({
    descriptionText: Joi.string().required(),
  });

  const issueForm = useForms(issueSchema, {}, async (formIsValid, formState) => {
    if (formIsValid) {
      await createIssue(null, currentUser, formState.descriptionText);

      reportModal.close();

      toast.success(_t("issue_reported"));
    }
  });

  if (!uiHeader.visible) {
    return null;
  }

  let navLinks = (
    <>
      <Link to={routes.jobsUpcoming} style={{ margin: "0 16px" }} className={pathname === routes.jobsUpcoming ? "isActive header--navlink" : "header--navlink"}>
        {_t("upcoming_jobs")}
      </Link>
      <Link to={routes.history} style={{ margin: "0 16px" }} className={pathname === routes.history ? "isActive header--navlink" : "header--navlink"}>
        {_t("history")}
      </Link>
      <Link to={routes.pastWorkers} style={{ margin: "0 16px" }} className={pathname === routes.pastWorkers ? "isActive header--navlink" : "header--navlink"}>
        {_t("past_workers")}
      </Link>
    </>
  );

  let signedInMenu = null; /*(
        <ContextMenu triggerElm={<div className="l-flex-center l-mob-hide" style={{width: "40px", height: "40px", borderRadius: "25px", marginLeft: "10px", background: "#00D0E2", color: "#fff", fontWeight: 500}}>
        <span>
            {currentUser.firstName[0]?.toUpperCase()}
            {currentUser.lastName[0]?.toUpperCase()}
        </span>
        </div>}>
            <a href={routes.terms} target="_blank">
                <div>Terms of Use</div>
            </a>
            <a href={routes.privacy} target="_blank">
                <div>Privacy Policy</div>
            </a>
            <div className="l-clickable" onClick={openLogoutDialog}>Log out</div>
        </ContextMenu>
    )*/

  return (
    <>
      <div className={`header ${(!isHome || isAuthPage || isWhite) && "isWhite"}`}>
        <div className="header--content l-content" style={{ maxWidth: 1250 }}>
          <div className="l-flex-center">
            {company ? (
              <Link to={routes.root} style={{ lineHeight: 0 }}>
                <LogoIcon className="header--logo" />
              </Link>
            ) : (
              <LogoIcon className="header--logo" />
            )}
            {company && navLinks}
          </div>

          <span className="l-flex-align-center">
            <div style={{ display: "inline-block", marginRight: "15px" }}>{signedInMenu}</div>

            <div className="l-flex-align-center">
              {company && !window.location.href.includes("create") && (
                <Link to={routes.jobCreate}>
                  <Button valid={true} style={{ marginRight: "45px" }}>
                    {_t("create_a_new_job")}
                  </Button>
                </Link>
              )}
              <ContextMenu triggerElm={<DrawerIcon />}>
                {/*<div className="l-inline-center" style={{padding: 0}}>*/}
                {/*    <div className="drawer-arrow l-inline-center"/>*/}
                {/*</div>*/}
                <Link to={routes.aboutCompany} className="blue-menu-item">
                  {_t("about_your_company")}
                </Link>
                <a href="https://www.aukazi.com/faq" target="_blank" className="blue-menu-item">
                  FAQ
                </a>
                <a onClick={() => reportModal.open()} className="blue-menu-item l-clickable">
                  {_t("contact_us")}
                </a>
                <a
                  onClick={() => {
                    setRating(rating);
                    ratingModal.open();
                  }}
                  className="l-clickable blue-menu-item"
                >
                  {_t("rate_us")}
                </a>
                <a href="https://www.aukazi.com/terms-and-conditions" target="_blank" className="blue-menu-item">
                  {_t("terms_and_conditions")}
                </a>
                <a href="https://www.aukazi.com/privacy-policy" target="_blank" className="blue-menu-item">
                  {_t("privacy_policy")}
                </a>
                <div className="blue-menu-item l-clickable" onClick={() => logoutModal.open()}>
                  {_t("log_out")}
                </div>

                <hr style={{ margin: "3px 0" }} />

                <a onClick={() => saveLanguagePreference()} className="blue-menu-item l-clickable">
                  {getSaveLanguagePreference() == "en" ? "Français" : "English"}
                </a>
              </ContextMenu>
            </div>
          </span>
        </div>
      </div>

      <Modal
        title="Logout"
        controlLeft={
          <div className="l-clickable" onClick={() => logoutModal.close()} style={{ color: "#325E8C" }}>
            <strong>{_t("no")}</strong>
          </div>
        }
        controlRight={
          <div className="l-clickable" onClick={_logout} style={{ color: "#325E8C" }}>
            <strong>{_t("yes")}</strong>
          </div>
        }
        {...logoutModal.modalProps}
      >
        {_t("you_will_be_disconnect_from_your_account")}
      </Modal>

      <Modal
        title={_t("having_an_issue")}
        controlLeft={
          <div className="l-clickable" style={{ color: "#666" }} onClick={reportModal.close}>
            <strong>{_t("cancel")}</strong>
          </div>
        }
        controlRight={
          <div className="l-clickable" style={{ color: "#325E8C" }} onClick={issueForm.onSubmit}>
            <strong>{_t("send")}</strong>
          </div>
        }
        {...reportModal.modalProps}
      >
        <div style={{ marginTop: "8px" }}>{_t("describe_your_issue_to_the_admin")}</div>

        <Field
          className="l-full-width"
          name="descriptionText"
          type="textarea"
          rows={4}
          variant="outlined"
          containerStyle={{ marginTop: "15px" }}
          {...issueForm.genericFieldProps}
        />
        <div style={{ fontSize: "12px", color: "#666666" }}>
          <div>{_t("if_you_need_assistance_call_us")}</div>
          <div>{_t("monday_to_friday")}</div>
        </div>
      </Modal>

      <Modal
        title={_t("how_is_your_experience")}
        controlLeft={
          <div className="l-clickable" style={{ color: "#666" }} onClick={ratingModal.close}>
            <strong>{_t("cancel")}</strong>
          </div>
        }
        controlRight={
          <div className="l-clickable" style={{ color: "#325E8C" }} onClick={ratingForm.onSubmit}>
            <strong>{_t("save")}</strong>
          </div>
        }
        {...ratingModal.modalProps}
      >
        <Rating
          value={rating}
          size="large"
          onChange={(_, newValue) => {
            console.log("newv", newValue);

            setRating(newValue);
          }}
        />

        <Field
          className="l-full-width"
          name="descriptionText"
          placeholder={_t("let_us_know_if_we_should_improve_things")}
          type="textarea"
          rows={4}
          variant="outlined"
          containerStyle={{ marginTop: "15px" }}
          {...ratingForm.genericFieldProps}
        />
      </Modal>
    </>
  );
}
