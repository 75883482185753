import React from "react";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { Field } from "../components/controls/field";
import { Link, useSearchParams } from "react-router-dom";
import { routes } from "../../app/routes";
import { Button } from "../components/controls/button";
import warehouseImg from "../../assets/images/Warehouse management system 2 1.png";
import { useHiddenHeader } from "../../app/services/ui-service";
import { useLocation, useNavigate } from "react-router";
import { inlineErr } from "../../app/utils/error-handler-util";
import { signIn } from "../../app/services/user-service";
import { Form, useForms } from "../components/controls/form";
import { usePreventBodyScrollEffect } from "../components/popups/popup-hooks";
import Joi from "joi";
import { getSaveLanguagePreference, saveLanguagePreference, _t } from "../../app/translate/translate-wrapper";

const schema = Joi.object({
  email: Joi.string().email({ tlds: false }).required().label("Email"),
  password: Joi.string().required().label("Password"),
  rememberMe: Joi.boolean(),
});

export default function SignInPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { login, ...extraSearchParams } = Object.fromEntries(searchParams.entries());

  usePreventBodyScrollEffect();

  const { onSubmit, genericFieldProps } = useForms(schema, {}, async (formIsValid, formState) => {
    if (formIsValid) {
      const [user, err] = await inlineErr(signIn(formState.email, formState.password));

      if (err) {
        console.error(err);
        alert(_t("check_your_email_and_password_and_try_again"));
      } else {
        if (user?.company) {
          navigate(routes.jobsUpcoming);
        } else {
          navigate(routes.aboutCompany);
        }
      }
    }
  });

  useHiddenHeader();

  return (
    <>
      <div className="l-flex-between" style={{ overflow: "hidden" }}>
        <Form
          style={{
            height: "100vh",
            flexGrow: 1,
            alignSelf: "start",
            overflow: "auto",
            position: "relative",
            zIndex: 1,
          }}
          onSubmit={onSubmit}
        >
          <div className="l-content" style={{ maxWidth: "475px" }}>
            <div style={{ marginTop: "62px" }}>
              <Link to={routes.root}>
                <LogoIcon />
              </Link>

              <a
                onClick={() => saveLanguagePreference()}
                className="blue-menu-item l-clickable"
                style={{ float: "right", marginTop: "5%" }}
              >
                {getSaveLanguagePreference() == "en" ? "Français" : "English"}
              </a>

              <h1 className="l-text-alternate-family" style={{ marginTop: "50px", marginBottom: "30px", lineHeight: "45px" }}>
                {_t("log_into_your_existing_company_account")}
              </h1>
            </div>

            <hr style={{ margin: "30px 0" }} />

            <Field className="l-full-width" name="email" label={_t("email_address")} variant="outlined" {...genericFieldProps} />
            <Field
              className="l-full-width"
              name="password"
              label={_t("password")}
              variant="outlined"
              type="password"
              {...genericFieldProps}
            />

            <div className="l-flex-between">
              <div className="l-flex-align-center">
                <Field checkbodNoLeftMargin name="rememberMe" type="checkbox" label={_t("remember_me")} {...genericFieldProps} />
              </div>
              <Link to={routes.forgotPassword}>
                <strong style={{ color: "#0F2F60" }}>{_t("password_forgotten")}</strong>
              </Link>
            </div>

            <Button className="l-full-width" valid={true} style={{ marginTop: "43px" }}>
              {_t("login")}
            </Button>
            <div className="l-inline-center" style={{ marginTop: "20px", color: "#666666", paddingBottom: "30px" }}>
              {_t("dont_have_an_account")}{" "}
              <Link to={routes.signup} style={{ color: "#28588C" }}>
                <strong>{_t("sign_up")}</strong>
              </Link>
            </div>
          </div>
        </Form>

        <div
          className="l-flex-center l-mob-hide"
          style={{ background: "#28588C", height: "100vh", zIndex: 2, position: "relative", flexShrink: 1 }}
        >
          <img src={warehouseImg} style={{ position: "relative", left: "-75px" }} />
        </div>
      </div>
    </>
  );
}
