import { Model } from "./_model";
import Parse from "parse";
import { JobModel } from "./job-model";

export class ForkliftModel extends Model {
  constructor() {
    super("Forklift");
  }

  get name(): string {
    return this.get("name") || "string";
  }

  get image(): Parse.File | undefined {
    return this.get("image");
  }

  get hasPayingOption(): boolean | undefined {
    return this.get("hasPayingOption");
  }
}

Parse.Object.registerSubclass("Forklift", ForkliftModel);
