import React, { useImperativeHandle, useRef } from "react";

export const StripeInput = ({ component: Component, inputRef, ...props }) => {
  const elementRef = useRef();

  useImperativeHandle(inputRef, () => ({
    focus: () => (elementRef?.current as any)?.focus,
  }));

  return <Component onReady={(element) => (elementRef.current = element)} {...props} />;
};
