import React, { useEffect, useState } from "react";
import { Column, Table, TableOderingOptsType } from "../components/layout/table";
import { HeaderSubtract } from "../components/layout/header-subtract";
import { generatePath, useNavigate } from "react-router";
import { routes } from "../../app/routes";
import {
  cancelJob,
  createIssue,
  createReview,
  getHomeFilterOptions,
  hasCompletedNotReviewedJobs,
  isJobLateCancellation,
  refreshCompanyJobsList,
  useCompanyJobsList,
} from "../../app/services/job-service";
import { JobModel } from "../../app/models/job-model";
import { formatDate } from "../../app/utils/date-util";

import { ReactComponent as CaseIcon } from "../../assets/icons/case.svg";
import { ReactComponent as FullTimeIcon } from "../../assets/icons/full_time_icon.svg";
import { ReactComponent as PartTimeIcon } from "../../assets/icons/part_time_icon.svg";
import {
  calendarLocalizedMonths,
  calendarLocalizedWeekDays,
  formatDateToLocale,
  getSaveLanguagePreference,
  LanguageOptions,
  translateJobTask,
  _t,
} from "../../app/translate/translate-wrapper";
import { getJobDateStartDateISO } from "../../app/utils/job-date.utils";
import moment from "moment";
import { Modal, useModal } from "../components/controls/modal";
import { Field } from "../components/controls/field";
import Joi from "joi";
import { useForms } from "../components/controls/form";
import { useCurrentUser } from "../../app/services/user-service";
import { toast } from "react-toastify";
import { ReactComponent as MenuIcon } from "../../assets/icons/menu-icon.svg";
import { Button } from "../components/controls/button";
import { Rating } from "@material-ui/lab";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
// import { Calendar, DateObject } from "react-multi-date-picker";
import dayjs from "dayjs";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRange } from "react-date-range";
import * as locales from "react-date-range/dist/locale";
import { CustomField } from "../components/controls/custom-field";
import { WorkerSchedulePreference } from "../../app/models/user-model";
import { Menu, MenuItem, Tooltip } from "@material-ui/core";
import JobActionsMenu from "../components/job-actions-menu";
import { getParseConfig, percentageCalc } from "../../app/utils/commons";
import { styled, withStyles } from '@material-ui/core/styles'
import { TooltipClassKey } from '@material-ui/core/Tooltip'

export type JobsPageProps = {
  listing: "upcoming" | "history";
};

const AvailabilityTooltip = withStyles(theme => ({
  arrow: {
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'black',
    padding: '8px 12px',
    fontSize: 14,
    fontFamily: 'Circular Std'
  },
  popper: {
    filter: "drop-shadow(4px 0px 20px rgba(160, 160, 160, 0.25))"
  }
}))(Tooltip)

export default function JobsPage(props: JobsPageProps) {
  const [rating, setRating] = useState(0 as any);
  const [ratingJob, setRatingJob] = useState<any>(null);
  const [rows, setRows] = useState<any>({});
  const navigate = useNavigate();
  const [jobs, setJobs] = useState<any[]>([]);
  const jobCompletedModal = useModal();
  const [isLoadingMoreVisible, setIsLoadingMoreVisible] = useState(true);

  const [filterOptions, setFilterOptions] = useState<any>({ tasks: [], statusOptions: [] });
  const [isFilterDateSelected, setIsFilterDateSelected] = useState(false);
  const [filterDateRange, setFilterDateRange] = useState<any>({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [filterSelectedStatus, setFilterSelectedStatus] = useState<any>(null);
  const [filterSelectedTask, setFilterSelectedTask] = useState<any>(null);
  const [appliedFilters, setAppliedFilters] = useState<any>(null);

  const [reportJob, setReportJob] = useState<any>(null);
  const currentUser = useCurrentUser();

  const reportModal = useModal();
  const filtersModal = useModal();
  const ratingModal = useModal();
  const cancelJobLateModal = useModal();
  const cancelJobModal = useModal();
  const loadingModal = useModal();
  const successCancelJobModal = useModal();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [lateCancellationLimit, setLateCancellationLimit] = useState(0);
  const [lateCancellationPercentage, setLateCancellationPercentage] = useState(0);
  const [lateCancellationMax, setLateCancellationMax] = useState(0);

  const [selectedJob, setSelectedJob] = useState<any>(null)

  const isFrench = getSaveLanguagePreference() == LanguageOptions.FR;

  let company = currentUser?.company;

  const getLateCancellation = async () => {
    setLateCancellationLimit(await getParseConfig("lateCancellationHoursLimit"));
    setLateCancellationPercentage(await getParseConfig("lateCancellationPercentage"));
    setLateCancellationMax(await getParseConfig("lateCancellationMaxValue"));
  };

  useEffect(() => {
    getFilterOptions();
    getLateCancellation();
  }, []);

  useEffect(() => {
    fetchCompletedJobs();
  }, [company, props.listing]);

  const calculateLateCancellationFee = (job) => {
    if (!job) return
    const fee = percentageCalc(lateCancellationPercentage, job?.payment.attributes.total);

    if (fee > lateCancellationMax) {
      return lateCancellationMax;
    }

    return fee;
  };

  const getSelectedFiltersCount = () => {
    let count = 0;

    if (isFilterDateSelected) count += 1;
    if (filterSelectedStatus) count += 1;
    if (filterSelectedTask) count += 1;

    return count;
  };

  const getAppliedFiltersCount = () => {
    let count = 0;

    if (appliedFilters) {
      if (isFilterDateSelected) count += 1;
      if (appliedFilters.status) count += 1;
      if (appliedFilters.task) count += 1;
    }

    return count;
  };

  const onApplyFilters = async () => {
    const filters: any = {
      dateRange: {
        startDate: filterDateRange.startDate,
        endDate: filterDateRange.endDate,
      },
      status: filterSelectedStatus,
      task: filterOptions.tasks.find((v) => v.id == filterSelectedTask)?.name,
    };

    setAppliedFilters(filters);

    const requestFilters = { ...filters };

    if (!isFilterDateSelected) requestFilters.dateRange = null;

    console.log(requestFilters);

    refreshCompanyJobsList(props.listing, [], requestFilters).then((result: any) => {
      setJobs(result);
      setIsLoading(false);
    });

    filtersModal.close();
  };

  const onResetFilters = async () => {
    setFilterDateRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
    setFilterSelectedStatus(null);
    setFilterSelectedTask(null);
    setIsFilterDateSelected(false);
  };

  const fetchCompletedJobs = async () => {
    const notifiedCompletedJobs = localStorage.getItem("notifiedCompletedJobs");

    if (props.listing == "history" && !notifiedCompletedJobs) {
      const hasCompletedJobs = await hasCompletedNotReviewedJobs(company);

      if (hasCompletedJobs) {
        jobCompletedModal.open();

        localStorage.setItem("notifiedCompletedJobs", "true");
      }
    }
  };

  const getFilterOptions = async () => {
    const options = await getHomeFilterOptions();

    if (props.listing == "upcoming") {
      const upcomingStatusList = [options.statusOptions[0], options.statusOptions[1], options.statusOptions[6]];

      setFilterOptions({
        statusOptions: upcomingStatusList,
        tasks: options.tasks,
      });
    } else {
      setFilterOptions(options);
    }
  };

  const issueSchema = Joi.object({
    descriptionText: Joi.string().required(),
  });

  const issueForm = useForms(issueSchema, {}, async (formIsValid, formState) => {
    if (formIsValid) {
      await createIssue(reportJob, currentUser, formState.descriptionText);

      reportModal.close();

      toast.success(_t("issue_reported"));
    }
  });

  useEffect(() => {
    refreshCompanyJobsList(props.listing, [], appliedFilters).then((result: any) => {
      setJobs(result);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    setRows(getRows());
  }, [jobs]);

  const canRateWorker = (job: JobModel) => {
    return job.status.includes("confirmed") && job.get("workerRating") == undefined;
  };

  const loadMoreHistoryJobs = () => {
    setIsLoadingMore(true);

    refreshCompanyJobsList(
      props.listing,
      jobs.map((job) => job.job.id),
      appliedFilters
    ).then((result: any[]) => {
      setJobs([...jobs, ...result]);
      setIsLoading(false);
      setIsLoadingMore(false);

      if (result.length == 0) setIsLoadingMoreVisible(false);
    });
  };

  const columns: Column[] = [
    { headerName: _t("starting_date"), field: "startDate", sortable: false },
    { headerName: _t("starting_time"), field: "startTime", sortable: false },
    { headerName: _t("days"), field: "days", sortable: false },
    { headerName: _t("hours"), field: "hours", sortable: false },
    { headerName: _t("task"), field: "mainTask", sortable: false },
    { headerName: _t("price"), field: "price", sortable: false },
    { headerName: _t("worker"), field: "worker", sortable: false },
    { headerName: _t("status"), field: "status", sortable: false },
    { headerName: props.listing == "history" ? "Rates" : "", field: props.listing == "history" ? "rates" : "", sortable: false, hasCustomClick: true },
    { headerName: "", field: "actions", sortable: false, hasCustomClick: true },
  ];

  const getWorkerNameAndSchedule = (name: any, preference: WorkerSchedulePreference) => {
    return (
      <div style={{ display: "flex", alignItems: "center", textAlign: "start" }}>
        <AvailabilityTooltip
          title={`${preference === WorkerSchedulePreference.FULL_TIME
            ? _t("available_full_time")
            : _t("available_part_time")}`
          }
          placement="top"
          arrow
        >
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexShrink: 0, width: 12, height: 12 }}>
            {
              preference === WorkerSchedulePreference.FULL_TIME
              ? <FullTimeIcon />
              : <PartTimeIcon />
            }
          </div>

        </AvailabilityTooltip>
        <span style={{ marginLeft: 12 }}>{name}</span>
      </div>
    );
  };

  const displayAlignedStart = (text: any) => {
    return <div style={{ textAlign: "start" }}>{text}</div>;
  };

  const doCancelJob = async () => {
    cancelJobLateModal.close();
    cancelJobModal.close();

    if (!selectedJob) return

    try {
      loadingModal.open();

      await cancelJob(selectedJob.id, currentUser);

      loadingModal.close();

      successCancelJobModal.open();
    } catch (err) {
      toast.error(_t("failed_to_cancel_this_job"));

      loadingModal.close();
    }
  }

  const getRows = () =>
    jobs
      .filter((it: any) => it.jobDates.length > 0)
      .map((it: any) => {
        const job = (it.job as JobModel) || {};
        const jobDates = it.jobDates;

        let jobHours = 0;

        jobDates.map((jobDate) => {
          const duration = jobDate.get("duration").toString();

          let durationMultiplier = ".00";
          let durationHour = "";

          if (duration) {
            if (duration.includes(".15")) durationMultiplier = ".25";
            if (duration.includes(".30") || duration.includes(".3")) durationMultiplier = ".50";
            if (duration.includes(".45")) durationMultiplier = ".75";

            durationHour = duration.split(".")[0];
            jobHours += Number(`${durationHour}${durationMultiplier}`);
          } else {
            jobHours += 0;
          }
        });

        const startDate = getJobDateStartDateISO(it.jobDates[0]);

        console.log(it);

        const rowObject = {
          ...it,
          id: job.id,
          startDate: displayAlignedStart(formatDateToLocale(moment(startDate).toDate())),
          startTime: displayAlignedStart(it.jobDates[0].get("startTime")),
          days: displayAlignedStart(it.jobDates.length || 0),
          hours: displayAlignedStart(jobHours.toFixed(2)),
          mainTask: displayAlignedStart(translateJobTask(job.mainTask)),
          price: displayAlignedStart(job.priceFmt),
          worker: it.workerName ? getWorkerNameAndSchedule(it.workerName, job.worker.schedulePreference) : displayAlignedStart("-"),
          status: displayAlignedStart(<span style={{ color: job.statusColor }}>{job.statusDescription}</span>),
          rates: getRateRow(job),
          actions: (
            <JobActionsMenu
              onDuplicate={() => {
                navigate(`/jobs/create/${job.id}`)
              }}
              onReport={() => {
                setReportJob(job);
                reportModal.open();
              }}
              {...(props.listing !== "history" && {
                onCancel: async () => {
                  setSelectedJob(job)
                  const isLateCancellation = await isJobLateCancellation(job?.id);
  
                  if (isLateCancellation && job?.status.includes("confirmed")) {
                    calculateLateCancellationFee(job);
  
                    cancelJobLateModal.open();
                  } else {
                    cancelJobModal.open();
                  }
                }
              })}
            />
          ),
          updatedAt: job.updatedAt,
        };

        console.log(rowObject);

        return rowObject;
      });

  const onItemClick = (row: JobModel) => {
    navigate(generatePath(routes.jobDetails, { id: row.id }));
  };

  const ratingSchema = Joi.object({
    descriptionText: Joi.any(),
  });

  const ratingForm = useForms(ratingSchema, { initialFormValue: { descriptionText: undefined } }, async (formIsValid, formState) => {
    if (formIsValid) {
      await createReview(ratingJob, ratingJob?.worker, rating, formState.descriptionText);

      ratingModal.close();

      toast.success(_t("rating_sent"));
    }
  });

  const getRateRow = (job) => {
    if (canRateWorker(job)) {
      return (
        <Button
          style={{ height: 50, width: 140, fontSize: 14 }}
          valid={true}
          onClick={() => {
            setRatingJob(job);
            setRating(0);
            ratingModal.open();
          }}
        >
          {_t("rate_worker_button")}
        </Button>
      );
    } else {
      if (job.get("workerRating") != null) {
        return <ReactStars count={5} value={job.get("workerRating")} size={16} activeColor="#ffd700" edit={false} />;
      } else return null;
    }
  };

  const statusDescription = (status: string): string => {
    switch (status) {
      case "adminCancelled":
        return _t("admin_cancelled")
      case "confirmed":
        return _t("confirmed");
      case "notFound":
        return _t("not_found");
      case "pending":
        return _t("pending");
      case "userCancelled":
      case "userCancelledByAdmin":
        return _t("worker_cancelled");
      case "userCancelledLate":
      case "userCancelledLateByAdmin":
        return _t("worker_cancelled_late");
      case "companyCancelled":
      case "companyCancelledByAdmin":
        return _t("you_cancelled");
      case "companyCancelledLate":
      case "companyCancelledLateByAdmin":
        return _t("you_cancelled_late");
      case "confirmedWithCancelledDays":
        return _t("confirmed_but_contains_cancelled_days");
      default:
        return _t("not_found");
    }
  };

  if (isLoading) {
    return (
      <div className="l-content" style={{ paddingBottom: "50px" }}>
        <HeaderSubtract>
          <div className="l-content">
            <div className="t-surface-card l-text-discreet l-inline-center" style={{ margin: "30px", padding: "25px" }}>
              <span className="center-information-table" style={{ color: "#325e8c", fontWeight: 700 }}>
                {_t("loading")}
              </span>
            </div>
          </div>
        </HeaderSubtract>
      </div>
    );
  }

  return (
    <>
      <HeaderSubtract style={{ height: 70, width: "100%" }}>
        <div style={{ backgroundColor: "#FBFBFB", width: "100%", paddingTop: 24, paddingBottom: 24 }}>
          <div className="l-content" style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ color: "#4F4F4F", fontWeight: 700, fontSize: "15px", marginTop: 8 }}>
              {jobs.filter((v) => v.job.worker != null).length} {_t("filter_workers")}
            </span>

            <Button
              style={{
                height: 36,
                width: 140,
                fontSize: 13,
                float: "right",
                padding: 10,
                backgroundColor: getAppliedFiltersCount() >= 1 ? "#DFAA08" : "#325E8C",
              }}
              valid={true}
              onClick={() => {
                filtersModal.open();
              }}
            >
              {getAppliedFiltersCount() >= 1 ? `${getAppliedFiltersCount()} ${_t("filters")}` : _t("add_filters")}
            </Button>
          </div>
        </div>
      </HeaderSubtract>

      <div className="l-content" style={{ paddingBottom: "50px", marginTop: 70, maxWidth: 1250 }}>
        <HeaderSubtract style={{ paddingTop: 10 }}>
          {jobs.length == 0 && !isLoading ? (
            <div className="l-content">
              <div className="t-surface-card l-text-discreet l-inline-center" style={{ margin: "30px", padding: "25px" }}>
                <span className="center-information-table">
                  <CaseIcon />
                </span>
                <br></br>
                <span className="center-information-table" style={{ color: "#325e8c", fontWeight: 700, fontSize: "15px", top: "51.5%" }}>
                  {_t("no_without_jobs")} {_t(props.listing)} {!isFrench && _t("jobs")}
                </span>
                <br></br>
                <span className="center-information-table" style={{ marginTop: "15px", color: "#A8A8A8", fontWeight: 400, fontSize: "14px", top: "53%" }}>
                  {_t("tap_on_the_button_create_a_new_job")}
                </span>
              </div>
            </div>
          ) : (
            <div style={{ textAlign: "center" }}>
              <Table
                columns={columns}
                rows={rows}
                onItemClick={onItemClick}
                itemKey="id"
                style={{ marginTop: "40px", boxShadow: "0px 3px 10px rgba(43, 43, 43, 0.1)", borderRadius: "10px" }}
              />

              {props.listing == "history" && isLoadingMoreVisible && (
                <Button
                  className="l-inline-center"
                  valid={true}
                  style={{ marginTop: "30px", opacity: isLoadingMore ? 0.5 : 1 }}
                  onClick={() => {
                    if (!isLoadingMore) {
                      loadMoreHistoryJobs();
                    }
                  }}
                >
                  {isLoadingMore ? _t("loading") : _t("load_more")}
                </Button>
              )}
            </div>
          )}
        </HeaderSubtract>

        <Modal
          title={`${getSelectedFiltersCount()} ${_t("filters")}`}
          controlLeft={
            <div className="l-clickable" style={{ color: "#666" }} onClick={filtersModal.close}>
              <strong>{_t("cancel")}</strong>
            </div>
          }
          {...filtersModal.modalProps}
        >
          <DateRange
            style={{ width: "100%" }}
            ranges={[filterDateRange]}
            onChange={(event) => {
              console.log(event);
              setFilterDateRange(event.selection);
              setIsFilterDateSelected(true);
            }}
            locale={locales[getSaveLanguagePreference() == LanguageOptions.EN ? "en" : "fr"]}
          />

          <hr />

          <div
            onClick={(e: any) => {
              setFilterSelectedStatus(e.target.value);

              console.log(e);
            }}
            style={{ textAlign: "left" }}
          >
            <CustomField
              defaultValue={filterSelectedStatus}
              name="jobStatus"
              label={_t("job_status")}
              type="select"
              options={filterOptions.statusOptions}
              selectValueToDisplay={(v) => statusDescription(v.id)}
              onChange={(_, value) => console.log("value", value)}
              variant="outlined"
              style={{ width: "100%", height: "80%" }}
            />
          </div>

          <div
            onClick={(e: any) => {
              console.log(e.target.value);
              setFilterSelectedTask(e.target.value);
            }}
            style={{ textAlign: "left" }}
          >
            <CustomField
              defaultValue={filterSelectedTask}
              name="task"
              label={_t("filter_task")}
              type="select"
              options={filterOptions.tasks}
              selectValueToDisplay={(v) => translateJobTask(v.id)}
              onChange={(_, value) => console.log("value", value)}
              variant="outlined"
              style={{ width: "100%", height: "80%" }}
            />
          </div>

          <div>
            <Button
              style={{ height: 36, width: "60%", fontSize: 15, float: "right", padding: 2 }}
              valid={true}
              onClick={() => {
                onApplyFilters();
              }}
            >
              {_t("apply")}
            </Button>

            <Button
              style={{ height: 36, width: "40%", fontSize: 15, float: "right", padding: 2, color: "#E56A6A", backgroundColor: "white" }}
              valid={true}
              onClick={() => {
                onResetFilters();
              }}
            >
              {_t("reset")}
            </Button>
          </div>
        </Modal>

        <Modal
          title={_t("having_an_issue")}
          controlLeft={
            <div className="l-clickable" style={{ color: "#666" }} onClick={reportModal.close}>
              <strong>{_t("cancel")}</strong>
            </div>
          }
          controlRight={
            <div className="l-clickable" style={{ color: "#325E8C" }} onClick={issueForm.onSubmit}>
              <strong>{_t("send")}</strong>
            </div>
          }
          {...reportModal.modalProps}
        >
          <div style={{ marginTop: "8px" }}>{_t("describe_your_issue_to_the_admin")}</div>

          <Field
            className="l-full-width"
            name="descriptionText"
            type="textarea"
            rows={4}
            variant="outlined"
            containerStyle={{ marginTop: "15px" }}
            {...issueForm.genericFieldProps}
          />
          <div style={{ fontSize: "12px", color: "#666666" }}>
            <div>{_t("if_you_need_assistance_call_us")}</div>
            <div>{_t("monday_to_friday")}</div>
          </div>
        </Modal>

        <Modal
          title={_t("rate_your_worker")}
          controlLeft={
            <div className="l-clickable" style={{ color: "#666" }} onClick={ratingModal.close}>
              <strong>{_t("cancel")}</strong>
            </div>
          }
          controlRight={
            <div className="l-clickable" style={{ color: "#325E8C" }} onClick={ratingForm.onSubmit}>
              <strong>{_t("save")}</strong>
            </div>
          }
          {...ratingModal.modalProps}
        >
          <Rating
            value={rating}
            size="large"
            onChange={(_, newValue) => {
              setRating(newValue);
            }}
          />

          <div style={{ marginTop: "8px" }}>{_t("your_comment_optional")}</div>

          <Field
            className="l-full-width"
            name="descriptionText"
            type="textarea"
            rows={4}
            placeholder={_t("your_comment_optional")}
            variant="outlined"
            containerStyle={{ marginTop: "15px" }}
            {...ratingForm.genericFieldProps}
          />
        </Modal>

        <Modal title={_t("review_past_workers")} {...jobCompletedModal.modalProps}>
          <div style={{ display: "inline-block" }}>
            <ReactStars count={5} value={5} size={16} activeColor="#ffd700" edit={false} />
          </div>

          <h3 style={{ fontSize: 14, lineHeight: "24px" }}>{_t("review_past_workers_text")}</h3>

          <div style={{ display: "inline-block", marginTop: 20 }}>
            <Button style={{ color: "#666666", backgroundColor: "transparent" }} valid={true} onClick={jobCompletedModal.close}>
              {_t("later")}
            </Button>

            <Link to={routes.history}>
              <Button valid={true} onClick={jobCompletedModal.close}>
                {_t("rate_them_now")}
              </Button>
            </Link>
          </div>
        </Modal>

        <Modal
          title={_t("cancel_this_job_question")}
          controlLeft={
            <div className="l-clickable" style={{ color: "#666" }} onClick={cancelJobLateModal.close}>
              <strong>{_t("cancel")}</strong>
            </div>
          }
          controlRight={
            <div className="l-clickable" style={{ color: "#325E8C" }} onClick={() => doCancelJob()}>
              <strong>{_t("yes")}</strong>
            </div>
          }
          {...cancelJobLateModal.modalProps}
        >
          {selectedJob && (
            <>
              {_t("if_you_cancel_now_you_will_get_cancellation_fees")} ${calculateLateCancellationFee(selectedJob)?.toFixed(2)}
            </>
          )}
        </Modal>

        <Modal
          title={_t("cancel_this_job_question")}
          controlLeft={
            <div className="l-clickable" style={{ color: "#666" }} onClick={cancelJobModal.close}>
              <strong>{_t("cancel")}</strong>
            </div>
          }
          controlRight={
            <div className="l-clickable" style={{ color: "#325E8C" }} onClick={() => doCancelJob()}>
              <strong>{_t("yes")}</strong>
            </div>
          }
          {...cancelJobModal.modalProps}
        >
          {_t("if_you_cancel_now_you_will_get_100_refund")}
        </Modal>

        <Modal title="Loading" {...loadingModal.modalProps}>
          {_t("we_are_processing_your_request")}
        </Modal>

        <Modal
          title={_t("job_cancelled")}
          {...successCancelJobModal.modalProps}
          controlRight={
            <div
              className="l-clickable"
              style={{ color: "#325E8C" }}
              onClick={() => {
                navigate(routes.root);
                window.location.reload();
              }}
            >
              <strong>Ok</strong>
            </div>
          }
        >
          {_t("job_successfully_cancelled")}
        </Modal>

      </div>
    </>
  );
}
