import React, { useState } from "react"
import { unescape } from "lodash"
import { getTranslation } from "../../app/services/job-service"
import { Button, ButtonProps } from "./controls/button"
import { toast } from "react-toastify"
import { _t } from "../../app/translate/translate-wrapper"

type TranslateButtonProps = ButtonProps & React.ButtonHTMLAttributes<any> & {
  textToTranslate?: string
  onTranslate: (text: string) => void
  sourceLang: string
  targetLang: string
}

export function TranslateButton(props: TranslateButtonProps) {

  const [isLoading, setIsLoading] = useState(false)

  const onTranslate = async () => {
    try {
      setIsLoading(true)
      // Use sourceLang and targetLang in the getTranslation function
      const translation = await getTranslation(props.textToTranslate as string, props.sourceLang, props.targetLang)
      props.onTranslate(unescape(translation))
    } catch (err) {
      toast.error(_t("unexpected_error_please_try_again"))
    } finally {
      setIsLoading(false)
    }
  }

  return <Button {...props} onClick={onTranslate} disabled={isLoading}>{props.children}</Button>
}
