import { Model } from "./_model";
import Parse from "parse";
import { formatMonetary } from "../utils/commons";
import { _t } from "../translate/translate-wrapper";

export class JobModel extends Model {
  constructor() {
    super("Job");
  }

  getTaskDescription() {
    return this.details || "-";
  }

  get liftWeight() {
    return this.get("liftWeight");
  }

  get cancellationFee() {
    return this.get("cancellationFee");
  }

  get updatedPayToWorker() {
    return this.get("updatedPayToWorker");
  }

  get forklifts() {
    return this.get("forklifts");
  }

  get payment() {
    return this.get("payment");
  }

  get paymentMethod() {
    return this.get("paymentMethod");
  }

  get requireWarehouseExperience() {
    return this.get("requireWarehouseExperience");
  }

  get originalPriceToCompany() {
    return this.get("originalPriceToCompany");
  }

  get lngAddress() {
    return this.get("lngAddress");
  }

  get originalPayToWorker() {
    return this.get("originalPayToWorker");
  }

  get status(): string {
    return this.get("status");
  }

  statusDetailDescription(lateCancellationLimit): string {
    switch (this.status) {
      case "adminCancelled":
        return _t("admin_cancelled");
      case "confirmed":
        return _t("we_have_an_worker_for_you");
      case "notFound":
        return _t("we_have_not_found_a_worker_refund_100");
      case "pending":
        return _t("we_are_looking_for_a_worker");
      case "userCancelled":
        return _t("the_worker_is_no_longer_available");
      case "userCancelledLate":
        return _t("the_worker_is_no_longer_available");
      case "companyCancelled":
        return _t("you_cancelled_this_job_refund_100");
      case "companyCancelledLate":
        return `${_t("you_cancelled_this_job_under")} ${lateCancellationLimit} ${_t("hours_before_it_starts")}`;
      case "confirmedWithCancelledDays":
        return _t("check_the_schedule_details");
      default:
        return _t("not_found");
    }
  }

  get statusDescription(): string {
    switch (this.status) {
      case "adminCancelled":
        return _t("admin_cancelled");
      case "confirmed":
        return _t("confirmed");
      case "notFound":
        return _t("not_found");
      case "pending":
        return _t("pending");
      case "userCancelled":
      case "userCancelledByAdmin":
        return _t("worker_cancelled");
      case "userCancelledLate":
      case "userCancelledLateByAdmin":
        return _t("worker_cancelled_late");
      case "companyCancelled":
      case "companyCancelledByAdmin":
        return _t("you_cancelled");
      case "companyCancelledLate":
      case "companyCancelledLateByAdmin":
        return _t("you_cancelled_late");
      case "confirmedWithCancelledDays":
        return _t("confirmed_but_contains_cancelled_days");
      default:
        return _t("not_found");
    }
  }

  get statusColor(): string {
    switch (this.status) {
      case "adminCancelled":
        return "#EB5757";
      case "confirmed":
        return "#219653";
      case "notFound":
        return "#A8A8A8";
      case "pending":
        return "#325E8C";
      case "userCancelled":
        return "#EB5757";
      case "userCancelledLate":
        return "#EB5757";
      case "companyCancelled":
        return "#EB5757";
      case "companyCancelledLate":
        return "#EB5757";
      case "confirmedWithCancelledDays":
        return "#F2994A";
      default:
        return "#A8A8A8";
    }
  }

  get updatedPriceToCompany() {
    return this.get("updatedPriceToCompany");
  }

  get latAddress() {
    return this.get("latAddress");
  }

  get specialInstruction() {
    return this.get("specialInstruction");
  }

  get specialInstructionFR() {
    return this.get("specialInstructionFR");
  }

  get additionalEmail() {
    return this.get("additionalEmail");
  }

  get preferredWorker() {
    return this.get("preferredWorker");
  }

  get details() {
    return this.get("details");
  }

  get detailsFR() {
    return this.get("detailsFR");
  }

  get languages() {
    return this.get("languages");
  }

  get isInvoiceSent() {
    return this.get("isInvoiceSent");
  }

  get company() {
    return this.get("company");
  }

  get mainTask() {
    return this.get("mainTask");
  }

  get worker(): any {
    return this.get("worker");
  }

  get hourlyCost(): any {
    return this.get("hourlyCost");
  }

  get hasForkliftOption(): any {
    return this.get("hasForkliftOption");
  }

  get startDate(): Date {
    return this.get("startDate") || new Date("01-01-2000");
  }

  get priceFmt(): string {
    return this.updatedPriceToCompany ? formatMonetary(this.updatedPriceToCompany) : formatMonetary(this.originalPriceToCompany);
  }

  get province(): string {
    return this.get("province") || "";
  }

  get city(): string {
    return this.get("city") || "";
  }

  get address(): string {
    return this.get("address") || "";
  }

  get postalCode(): string {
    return this.get("postalCode") || "";
  }

  get hourlyRate(): number {
    return this.get("hourlyRate") || "";
  }
}

Parse.Object.registerSubclass("Job", JobModel);
