import React, { useEffect, useState } from "react";
import { createReview, createWorkerComment, fetchPastWorkers } from "../../app/services/job-service";
import { useCurrentUser } from "../../app/services/user-service";
import { HeaderSubtract } from "../components/layout/header-subtract";
import { Column, Table } from "../components/layout/table";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo_blue_background.svg";
import { ReactComponent as CommentIcon } from "../../assets/icons/past_workers_comment.svg";
import { ReactComponent as CancelIcon } from "../../assets/icons/cancel_icon.svg";
import { Button } from "../components/controls/button";
import ReactStars from "react-rating-stars-component";
import { Modal, useModal } from "../components/controls/modal";
import { _t } from "../../app/translate/translate-wrapper";
import { Field } from "../components/controls/field";
import Joi from "joi";
import { useForms } from "../components/controls/form";
import { toast } from "react-toastify";
import { Rating } from "@material-ui/lab";
import Parse from "parse";

export default function PastWorkersPage() {
  const currentUser = useCurrentUser();
  const company = currentUser?.company;

  const commentModal = useModal();

  const [workers, setWorkers] = useState<any[]>([]);
  const [selectedWorker, setSelectedWorker] = useState<any>(null);

  const [rating, setRating] = useState(0 as any);
  const ratingModal = useModal();

  const ratingSchema = Joi.object({
    descriptionText: Joi.any(),
  });

  const ratingForm = useForms(ratingSchema, { initialFormValue: { descriptionText: undefined } }, async (formIsValid, formState) => {
    if (formIsValid) {
      await createReview(selectedWorker?.lastJob, Parse.User.createWithoutData(selectedWorker?.id), rating, formState.descriptionText);
      await getPastWorkers();

      ratingModal.close();

      toast.success(_t("rating_sent"));
    }
  });

  const commentFormSchema = Joi.object({
    commentText: Joi.any().required(),
  });

  const commentForm = useForms(commentFormSchema, {}, async (formIsValid, formState) => {
    if (formIsValid) {
      if (selectedWorker?.companyComment) {
        selectedWorker?.companyComment?.set("comment", formState.commentText);
        await selectedWorker?.companyComment?.save();
      } else {
        await createWorkerComment(company, selectedWorker.id, formState.commentText);
      }

      await getPastWorkers();

      commentModal.close();

      toast.success(_t("notes_saved"));
    }
  });

  useEffect(() => {
    getPastWorkers();
  }, []);

  const getPastWorkers = async () => {
    const workers = await fetchPastWorkers();

    setWorkers(workers);
  };

  const deleteComment = async () => {
    await selectedWorker?.companyComment?.destroy();
    await getPastWorkers();

    commentModal.close();

    toast.success(_t("comment_deleted"));
  };

  const getWorkerPicture = (worker: any) => {
    const style = { overflow: "hidden", borderRadius: "5px", width: "50px", height: "50px" };

    const image = worker.picture ? <img src={worker.picture._url} style={{
      ...style,
      objectFit: 'cover'
    }} /> : <LogoIcon style={{
      ...style,
      objectFit: 'cover'
    }} />;

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        {image} <span style={{ marginLeft: 24 }}>{worker.name}</span>
      </div>
    );
  };

  const getRateRow = (worker) => {
    if (!worker.isLastJobRated) {
      return (
        <Button
          style={{ height: 50, width: 140, fontSize: 14 }}
          valid={true}
          onClick={() => {
            setSelectedWorker(worker);
            setRating(0);
            ratingModal.open();
          }}
        >
          Rate last job
        </Button>
      );
    } else {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <ReactStars count={5} value={worker.averageRating} size={16} activeColor="#ffd700" edit={false} />
          <span style={{ marginLeft: 8, color: "#A8A8A8", fontSize: 14, marginTop: 4 }}>({worker.averageRating})</span>
        </div>
      );
    }
  };

  const columns: Column[] = [
    { headerName: _t("name"), field: "name", sortable: false },
    { headerName: _t("completed_hours"), field: "completedHours", sortable: false },
    { headerName: _t("your_average_rating"), field: "averageRating", sortable: false },
    { headerName: "", field: "actions", sortable: false, hasCustomClick: true },
  ];

  const getRows = () =>
    workers.map((it: any) => {
      const rowObject = {
        id: it.id,
        name: getWorkerPicture(it),
        completedHours: it.completedHours,
        averageRating: getRateRow(it),
        actions: (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedWorker(it);

              commentForm.formState.commentText = it.companyComment ? it.companyComment.get("comment") : "";

              commentForm.setFormValue({
                ...commentForm.formState,
              });

              commentModal.open();
            }}
          >
            <CommentIcon />
          </div>
        ),
      };

      return rowObject;
    });

  return (
    <>
      <HeaderSubtract style={{ height: 70, width: "100%" }}>
        <div style={{ backgroundColor: "#FBFBFB", width: "100%", paddingTop: 24, paddingBottom: 24 }}>
          <div className="l-content" style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ color: "#4F4F4F", fontWeight: 700, fontSize: "15px", marginTop: 8 }}>{workers.length} {_t("workers_who_already_worked_for_you")}</span>
          </div>
        </div>
      </HeaderSubtract>

      <div className="l-content" style={{ paddingBottom: "50px", marginTop: "5%" }}>
        <Table
          columns={columns}
          rows={getRows()}
          itemKey="id"
          style={{ marginTop: "40px", boxShadow: "0px 3px 10px rgba(43, 43, 43, 0.1)", borderRadius: "10px" }}
        />
      </div>

      <Modal title={_t("your_notes_about_this_worker")} controlLeft={<CancelIcon onClick={() => commentModal.close()} />} {...commentModal.modalProps}>
        <div>
          <Field
            className="l-full-width"
            name="commentText"
            placeholder={_t("type_your_notes_here")}
            type="textarea"
            rows={12}
            variant="outlined"
            containerStyle={{ marginTop: "15px" }}
            {...commentForm.genericFieldProps}
          />

          <Button
            style={{ height: 36, width: "60%", fontSize: 15, float: "right", padding: 2, opacity: commentForm.valid ? 1 : 0.5 }}
            valid={true}
            onClick={() => {
              if (commentForm.valid) {
                commentForm.onSubmit();
              }
            }}
          >
            {selectedWorker?.companyComment ? _t("save") : _t("add")}
          </Button>

          <Button
            style={{ height: 36, width: "40%", fontSize: 15, float: "right", padding: 2, color: "#E56A6A", backgroundColor: "white" }}
            valid={true}
            onClick={() => {
              if (selectedWorker.companyComment) {
                deleteComment();
              }
            }}
          >
            {selectedWorker?.companyComment ? _t("delete") : ""}
          </Button>
        </div>
      </Modal>

      <Modal
        title={_t("rate_your_worker")}
        controlLeft={
          <div className="l-clickable" style={{ color: "#666" }} onClick={ratingModal.close}>
            <strong>{_t("cancel")}</strong>
          </div>
        }
        controlRight={
          <div className="l-clickable" style={{ color: "#325E8C" }} onClick={ratingForm.onSubmit}>
            <strong>{_t("save")}</strong>
          </div>
        }
        {...ratingModal.modalProps}
      >
        <Rating
          value={rating}
          size="large"
          onChange={(_, newValue) => {
            setRating(newValue);
          }}
        />

        <div style={{ marginTop: "8px" }}>{_t("your_comment_optional")}</div>

        <Field
          className="l-full-width"
          name="descriptionText"
          type="textarea"
          rows={4}
          placeholder={_t("your_comment_optional")}
          variant="outlined"
          containerStyle={{ marginTop: "15px" }}
          {...ratingForm.genericFieldProps}
        />
      </Modal>
    </>
  );
}
