import React, { useEffect } from "react";
import { Header } from "./components/layout/header";
import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../app/routes";
import { WhiteTheme } from "./themes";
import SignInPage from "./pages/sign-in-page";
import SignUpPage from "./pages/sign-up-page";
import { ForgotPasswordPage } from "./pages/forgot-password-page";
import AboutCompanyPage from "./pages/about-company-page";
import JobsPage from "./pages/jobs-page";
import JobDetailsPage from "./pages/job-details-page";
import JobCreatePage from "./pages/job-create/job-create-page";
import { useCurrentUser } from "../app/services/user-service";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PastWorkersPage from "./pages/past-workers";

//@ts-ignore
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function Redirect({ user }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      return navigate(routes.signin, { replace: true });
    } else {
      if (user.company) {
        navigate(routes.jobsUpcoming, { replace: true });
      } else {
        navigate(routes.aboutCompany, { replace: true });
      }
    }
  }, []);

  return <></>;
}

function Pages() {
  const currentUser = useCurrentUser();
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  }, [pathname]);

  return (
    <>
      <Routes>
        {!currentUser && (
          <>
            <Route path={routes.signin}>
              <SignInPage />
            </Route>
            <Route path={routes.signup}>
              <SignUpPage />
            </Route>
            <Route path={routes.forgotPassword}>
              <ForgotPasswordPage />
            </Route>
            <Route path="/*" element={<Redirect user={currentUser} />} />
          </>
        )}

        {currentUser && (
          <>
            <Route path={routes.aboutCompany}>
              <AboutCompanyPage />
            </Route>
            <Route path={routes.jobsUpcoming}>
              <JobsPage key="upcoming" listing="upcoming" />
            </Route>
            <Route path={routes.history}>
              <JobsPage key="history" listing="history" />
            </Route>
            <Route path={routes.pastWorkers}>
              <PastWorkersPage />
            </Route>
            <Route path={routes.jobCreate}>
              <Elements stripe={stripePromise}>
                <JobCreatePage />
              </Elements>
            </Route>
            <Route path={routes.jobDuplicate}>
              <Elements stripe={stripePromise}>
                <JobCreatePage />
              </Elements>
            </Route>
            <Route path={routes.jobDetails}>
              <JobDetailsPage />
            </Route>
            <Route path="/*" element={<Redirect user={currentUser} />} />
          </>
        )}
      </Routes>
    </>
  );
}

function App() {
  return (
    <WhiteTheme>
      <div className="App">
        <BrowserRouter>
          <Header />
          <Pages />
        </BrowserRouter>
      </div>
    </WhiteTheme>
  );
}

export default App;

