import Parse from "parse";
import { UserModel } from "../models/user-model";
import { generateInitialState, globalStore } from "../state/store";

export async function logout() {
  await Parse.User.logOut();
  globalStore.update((s) => ({ ...generateInitialState() }));

  window.location.reload();
}

export async function signIn(email: string, password: string): Promise<UserModel> {
  const loginResult = await Parse.User.logIn(email.toLowerCase(), password);
  const user = new UserModel();
  user.set("id", loginResult.id);
  await user.fetch();

  if (user.type == "worker") {
    await Parse.User.logOut();
    globalStore.update((s) => ({ ...generateInitialState() }));

    throw Error("Invalid user.");
  } else {
    globalStore.update((s) => {
      s.auth = user as unknown as UserModel;
    });

    return user as unknown as UserModel;
  }
}

export async function signUp(data): Promise<boolean> {
  const user = new Parse.User();
  user.set("username", data.email);
  user.set("email", data.email.toLowerCase());
  user.set("password", data.password);
  user.set("type", "company");
  const userResult = await user.signUp();

  //Runs login async
  signIn(data.email, data.password);

  console.log("Saved user id", userResult.id);
  return true;
}

export function useCurrentUser(): UserModel | undefined {
  return globalStore.useState((s) => s.auth);
}

export async function resetPassword(email: string) {
  const query = new Parse.Query(UserModel);
  query.equalTo("email", email.toLowerCase());
  const user = await query.first();

  if (!user) {
    const error = { type: "userNotFound" };
    throw error;
  }

  await Parse.User.requestPasswordReset(email);
  globalStore.update((s) => {
    s.resetEmail = email;
  });
}

export function useResetEmail() {
  return globalStore.useState((s) => s.resetEmail);
}

export async function invite(name, email, companyName, companyId) {
  return await Parse.Cloud.run("invite", {
    name,
    email,
    company: companyName,
    companyId: companyId,
  });
}

export async function refreshUser() {
  const user = globalStore.getRawState().auth;

  if (user) {
    const query = new Parse.Query(UserModel);
    query.equalTo("objectId", user?.id);
    query.include("company");
    const refreshedUser = await query.first();
    globalStore.update((s) => {
      s.auth = refreshedUser?.newInstance();
    });
  }
}

export async function getUserById(id: any) {
  const query = new Parse.Query(UserModel);
  query.equalTo("objectId", id);
  query.include("workerIdentity");
  query.include("workerExperience");

  return await query.first();
}
