import React from "react";

type HeaderSubtractParams = { style?: React.CSSProperties | undefined; children: React.ReactNode };

export function HeaderSubtract(props: HeaderSubtractParams) {
  return (
    <div className="header-subtract" style={props.style}>
      <div style={{ width: "100%", height: "100%", objectFit: "cover" }}>{props.children}</div>
    </div>
  );
}
