import React, { useState } from "react";
import Joi, { ValidationErrorItem } from "joi";
import { set, get } from "lodash";

type FormProps = {
  children: any;
  onSubmit: (e: React.FormEvent) => void;
};

export function Form({ children, onSubmit, ...props }: FormProps & React.HTMLProps<any>) {
  const internalOnSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit && onSubmit(e);
  };

  return (
    <form onSubmit={internalOnSubmit} {...props}>
      {children}
    </form>
  );
}

export type UseFormsResult<T = any> = {
  valid: boolean;
  setFormValue: (nextFormState: T) => void;
  submitted: boolean;
  onSubmit: () => boolean;
  setFieldError: (field, msg) => void;
  formState: [T, React.Dispatch<React.SetStateAction<T>>][0];
  resetForm: () => void;
  genericFieldProps: {
    schema: Joi.ObjectSchema;
    validationResult: Joi.ValidationResult | undefined;
    onChange: (name, value) => void;
    formState: [T, React.Dispatch<React.SetStateAction<T>>][0];
    validate: boolean;
  };
};

export function useForms<T = any>(
  joiSchema: Joi.ObjectSchema,
  opts: { initialFormValue?: any; onFieldChange?: { [fieldName: string]: (name, value, formState) => void } } = {},
  onSubmit?: (formIsValid: boolean, formState: T) => void
): UseFormsResult<T> {
  const initialFormData = (opts.initialFormValue || {}) as T;

  const [formState, setFormState] = useState(initialFormData);
  const [submitted, setSubmitted] = useState(false);
  const [validationResult, setValidationResult] = useState(undefined as Joi.ValidationResult | undefined);

  const setFormValue = (nextFormState) => {
    const _nextFormState = { ...formState, ...nextFormState };
    setFormState(_nextFormState);
    setValidationResult(joiSchema.validate(_nextFormState, { abortEarly: false }));
  };

  const internalOnSubmit = () => {
    console.log("form", formState);
    setSubmitted(true);
    const validationResult = joiSchema.validate(formState, { abortEarly: false });
    console.log("result", validationResult);
    setValidationResult(validationResult);
    const formIsValid = Boolean(validationResult && !validationResult.error);
    onSubmit && onSubmit(formIsValid, formState);
    return formIsValid;
  };

  let _changeField = (name, value) => {
    let nextState = { ...formState };
    //@ts-ignore
    set(nextState, name, value);
    setFormValue(nextState);
  };

  const genericFieldProps = {
    onChange: _changeField,
    schema: joiSchema,
    formState: formState,
    validate: submitted,
    validationResult: submitted ? (validationResult as Joi.ValidationResult) : undefined,
  };

  const setFieldError = (field, msg) => {
    const errorItem: ValidationErrorItem = {
      type: "error",
      context: undefined,
      path: field,
      message: msg,
    };

    //@ts-ignore
    const nextDetails = validationResult?.error ? [...validationResult.details, errorItem] : [errorItem];

    //@ts-ignore
    const nextValidationResult: Joi.ValidationResult = { ...validationResult, error: { ...validationResult.error, details: nextDetails } };

    setValidationResult(nextValidationResult);
  };

  const resetForm = () => {
    setFormState({ ...initialFormData });
    setValidationResult(undefined);
    setSubmitted(false);
  };

  return {
    onSubmit: internalOnSubmit,
    setFormValue,
    resetForm,
    genericFieldProps,
    formState,
    setFieldError,
    submitted,
    valid: Boolean(validationResult && !validationResult.error),
  };
}
