import React from "react";
import {ReactComponent as MoreIcon} from "../../assets/icons/more-horizontal.svg";
import {Menu} from "@material-ui/core";

type ContextMenuParams = {
    triggerElm?: JSX.Element;
    children?: any
};

export function ContextMenu(props: ContextMenuParams) {
    const triggerElm = props.triggerElm === undefined ? <span style={{border: "1px solid #d9d9d9", padding: "0 8px 5px 8px", borderRadius: "5px"}}><MoreIcon/></span> : props.triggerElm;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = (event: any) => {
        setAnchorEl(null);
        event.stopPropagation();
    };

    return <div style={{display: "inline-block", cursor: "pointer"}} onClick={handleClose}>
        <div className="l-flex-align-center" onClick={handleClick}>
            {triggerElm}
        </div>
        <Menu id="simple-menu" className="blue-menu" getContentAnchorEl={null}
              transformOrigin={{ vertical: "top", horizontal: "center" }} anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {props.children}
        </Menu>
    </div>;
}