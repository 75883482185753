import Parse from "parse";

export const formatMonetary = (value) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(value);
};

export const timeToNumber = (time) => {
  console.log("time", time);
  const splitedTime = time._d.toString().split(" ");

  const timeSplit = splitedTime[4].split(":");

  return `${parseInt(timeSplit[0])}.${timeSplit[1]}`;
};

export const numberToTime = (number) => {
  const time = number.toString().length == 4 ? `0${number}`.replace(".", ":") : number.replace(".", ":");

  return time;
};

export const getParseConfig = async (key) => {
  const configs = await Parse.Config.get();

  return configs.get(key);
};

export const percentageCalc = (percentage, value) => {
  return (percentage * value) / 100;
};

export const calculteWorkerRating = (ratings) => {
  let review1Count = 0;
  let review2Count = 0;
  let review3Count = 0;
  let review4Count = 0;
  let review5Count = 0;

  ratings.map((rev) => {
    const rating = rev || 0;

    if (rating == 1) review1Count += 1;
    if (rating == 2) review2Count += 2;
    if (rating == 3) review3Count += 3;
    if (rating == 4) review4Count += 4;
    if (rating == 5) review5Count += 5;
  });

  const calc1 = 5 * review5Count + 4 * review4Count + 3 * review3Count + 2 * review2Count + 1 * review1Count;
  const calc2 = review5Count + review4Count + review3Count + review2Count + review1Count;

  return calc1 == 0 && calc2 == 0 ? 0 : (calc1 / calc2).toFixed(2);
};
