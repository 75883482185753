import { refreshUser, useCurrentUser } from "./user-service";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import Parse from "parse";
import { useEffect, useState } from "react";

export function usePaymentCommons() {
  const currentUser = useCurrentUser();
  const stripe = useStripe()!;
  const elements = useElements()!;

  const saveCreditCard = async (fullName: string): Promise<any> => {
    if (!elements || !stripe) return;

    const card = elements.getElement("cardNumber");

    try {
      const cardToken = await stripe.createToken(card!, { name: fullName });
      if (cardToken.error) {
        // Inform the user if there was an error.
        alert("Check your credit card information");
        console.error(cardToken);
      } else {
        const cardData = await Parse.Cloud.run("create-card", { userId: currentUser?.id, cardToken: cardToken });
        await refreshUser();

        return cardData;
      }
    } catch (e) {
      alert("Unexpected error while communicating with the server, try again or contact the support");
      console.error(e);
      return;
    }
  };

  return {
    saveCreditCard,
  };
}

export function useListRegisteredCards() {
  const [cards, setCards] = useState([] as any[]);

  useEffect(() => {
    Parse.Cloud.run("retrieve_cards").then((cards) => {
      setCards(cards);
    });
  }, []);

  return cards;
}

export async function registerPayment(arg: any) {
  // try {
  //     const registerResponse = await Parse.Cloud.run("register-payment-success", {
  //         workoutProgramId: checkoutCommons.workoutProgramId,
  //         country: (checkoutCommons.form.formState)["country"],
  //         provinceId: checkoutCommons.selectedProvince?.id,
  //         province: checkoutCommons.selectedProvince?.name,
  //         promocode: checkoutCommons.form.formState["promocode"],
  //         subtotal: checkoutCommons.subTotal,
  //         total: checkoutCommons.total,
  //         tax: checkoutCommons.tax,
  //         provider
  //     });
  //
  //     if (registerResponse === "ok") {
  //         setNewBoughtItem(checkoutCommons.workoutProgramResult.item!)
  //         checkoutCommons.navigate(generatePath(routes.paymentComplete, {id: checkoutCommons.workoutProgramId}))
  //     } else {
  //         throw "Workout program not registered"
  //     }
  // } catch (e) {
  //     console.error(e)
  //     alert("Payment succeeded, but an error ocurred while activating the workout program\nPlease contact the support");
  // }
}

export async function createPaymentIntent(
  jobReq: { job: any; forklifts: any[]; jobDates: { date: Date; duration: number }[]; totals: any },
  card
) {
  return Parse.Cloud.run("payment_intent", { jobReq, card });
}
