export const routes = {
  signup: "/signup",
  signin: "/signin",
  reset: "/reset",
  myAccount: "/my-account",
  payment: "/payment",
  terms: "/terms",
  resetSent: "/reset-sent",

  privacy: "/privacy",
  jobsUpcoming: "/jobs",
  jobDetails: "/jobs/:id",
  jobCreate: "/jobs/create",
  jobDuplicate: "/jobs/create/:id",
  history: "/history",
  pastWorkers: "/pastWorkers",

  checkoutStripe: "?paymentMethod=stripe&buyWorkoutId=:id",
  checkoutPaypal: "?paymentMethod=paypal&buyWorkoutId=:id",
  myDashboard: "/dashboard",

  aboutCompany: "/about-company",

  faq: "/faq",
  root: "/",
  forgotPassword: "/forgot-password",

  admin: "/3t8nqz1l0sm0aevl",
  adminItem: "/3t8nqz1l0sm0aevl?config-seats=:id",
};

