export async function inlineErr<T = any>(
  arg: Promise<T>,
  logError = true,
  exit?: boolean
): Promise<[T | undefined, Error?]> {
  try {
    return [await arg];
  } catch (err) {
    if (logError) {
      console.error(err);
    }
    if (exit) {
      process.exit(1);
    }
    return [undefined, err];
  }
}
