import Joi from "joi";
import Parse from "parse";
import { CompanyModel } from "./company-model";

export const userSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
  password: Joi.string().min(3).required(),
  keepSession: Joi.boolean(),
});

export class UserModel extends Parse.User {
  password = "";

  get type(): string {
    return this.get("type") || "";
  }

  get firstName(): string {
    return this.get("firstName") || "";
  }

  get lastName(): string {
    return this.get("lastName") || "";
  }

  get email(): string {
    return this.get("email") || "";
  }

  get company(): CompanyModel | undefined {
    return this.get("company");
  }

  get last4(): string {
    return this.get("last4") || "";
  }

  get workerIdentity(): any {
    return this.get("workerIdentity");
  }

  get schedulePreference(): WorkerSchedulePreference {
    const preference = this.get("schedulePreference");

    switch (preference) {
      case "fullTime":
        return WorkerSchedulePreference.FULL_TIME;
      case "partTime":
        return WorkerSchedulePreference.PART_TIME;
      default:
        return WorkerSchedulePreference.FULL_TIME;
    }
  }
}

Parse.Object.registerSubclass("_User", UserModel);

export enum WorkerSchedulePreference {
  FULL_TIME,
  PART_TIME,
}
