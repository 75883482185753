import { useEffect } from "react";
import { globalStore } from "../state/store";

export function useHiddenHeader() {
  useEffect(() => {
    globalStore.update((s) => {
      s.ui.header.visible = false;
    });
    return () =>
      globalStore.update((s) => {
        s.ui.header.visible = true;
      });
  });
}

export function useHeaderState() {
  return globalStore.useState((s) => s.ui.header);
}
