import Parse from "parse";

export abstract class Model extends Parse.Object {
  constructor(className: string) {
    super(className);
    Parse.Object.registerSubclass(className, this.constructor as any);
  }

  get id(): string {
    return this.get("id");
  }
}
