import { Menu, MenuItem } from '@material-ui/core';
import * as React from 'react';

type JobActionsMenuProps = {
  onDuplicate: () => void
  onReport: () => void
  onCancel?: () => void
}

const ActionItem = ({ onClick, children, icon: Icon, color }) => {
  return (
    <MenuItem onClick={onClick}>
      <div style={{
        display: 'flex',
        alignItems:'center',
        color
      }}>
        <Icon />
        <span style={{
          marginLeft: '16px',
          fontSize: '14px'
        }}>{children}</span>
      </div>
    </MenuItem>
  )
}

const JobActionsMenu = ({ onDuplicate, onReport, onCancel }: JobActionsMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleActionClick = action => {

    handleClose()

    if (action === 'duplicate') {
      onDuplicate()
      return
    }
    if (action === 'report') {
      onReport()
      return
    }
    if (onCancel && action === 'cancel') {
      onCancel()
      return
    }
  }

  return (
    <div>
      <button
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{
          background: 'none',
          border: 0,
          cursor: 'pointer',
        }}
      >
        <svg
          width="16"
          height="14"
          fill="none"
          viewBox="0 0 16 14"
        >
          <path
            fill="#4F4F4F"
            d="M15 6H1c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1zM15 0H1C.4 0 0 .4 0 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1zM15 12H1c-.6 0-1 .4-1 1s.4 1 1 1h14c.6 0 1-.4 1-1s-.4-1-1-1z"
          ></path>
        </svg>
      </button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ActionItem onClick={() => handleActionClick('duplicate')} color="#325E8C" icon={() => (
          <svg
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <g fill="currentColor" clipPath="url(#clip0_5361_1969)">
              <path d="M14.09 0H3.183a1.818 1.818 0 00-1.818 1.818v12.727h1.818V1.819H14.09V0z"></path>
              <path d="M16.818 3.636h-10A1.818 1.818 0 005 5.454v12.728C5 19.186 5.814 20 6.818 20h10a1.818 1.818 0 001.818-1.818V5.454a1.818 1.818 0 00-1.818-1.818zm0 14.546h-10V5.454h10v12.728z"></path>
            </g>
            <defs>
              <clipPath id="clip0_5361_1969">
                <path fill="#fff" d="M0 0H20V20H0z"></path>
              </clipPath>
            </defs>
          </svg>
        )}>
          Duplicate this job
        </ActionItem>
        <ActionItem onClick={() => handleActionClick('report')} color="#F2994A" icon={() => (
          <svg
            width="20"
            height="20"
            fill="none"
            viewBox="0 0 20 20"
          >
            <g clipPath="url(#clip0_5826_156)">
              <path
                fill="currentColor"
                d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm0 15c-.75 0-1.25-.5-1.25-1.25s.5-1.25 1.25-1.25 1.25.5 1.25 1.25S10.75 15 10 15zm1.25-3.75h-2.5V5h2.5v6.25z"
              ></path>
            </g>
            <defs>
              <clipPath id="clip0_5826_156">
                <path fill="#fff" d="M0 0H20V20H0z"></path>
              </clipPath>
            </defs>
          </svg>
        )}>
          Report an issue
        </ActionItem>
        {onCancel && (
          <ActionItem onClick={() => handleActionClick('cancel')} color="#EB5757" icon={() => (
            <svg
              width="20"
              height="20"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="square"
                strokeMiterlimit="10"
                strokeWidth="1.5"
                d="M15.833 4.167L4.167 15.833M15.833 15.833L4.167 4.167"
              ></path>
            </svg>
          )}>
            Cancel this job
          </ActionItem>
        )}
      </Menu>
    </div>
  );
}

export default JobActionsMenu
