import { globalStore } from "../state/store";
import { dictionaryToList, listToDictionary } from "../utils/dictionary-util";
import { JobModel } from "../models/job-model";
import Parse from "parse";
import { Model } from "../models/_model";
import { CompanyModel } from "../models/company-model";
import { ForkliftModel } from "../models/forklift-model";
import { JobsPageProps } from "../../ui/pages/jobs-page";
import { _t } from "../translate/translate-wrapper";
import moment from "moment";
import { getJobDateStartDateTime, getNowDateWithTz } from "../utils/job-date.utils";

type Opts<T extends Model> = {
  filter?: (query: Parse.Query<T>) => void | Parse.Query<T>;
};

export async function getHomeFilterOptions() {
  const user = await Parse.User.current();

  if (user) {
    return await Parse.Cloud.run("getHomeFilterOptions", {
      userId: user["id"],
    });
  }
}

export function useCompanyJobsList(direction: JobsPageProps["listing"]): JobModel[] {
  return globalStore.useState((s) => {
    return dictionaryToList(s[direction == "upcoming" ? "jobsUpcoming" : "jobsHistory"]);
  });
}

export async function refreshCompanyJobsList(direction: JobsPageProps["listing"], fetchedJobsIdList: string[] = [], appliedFilters: any = null) {
  const user = await Parse.User.current();

  if (user) {
    const jobs = await Parse.Cloud.run(direction == "upcoming" ? "getCompanyUpcomingJobs" : "getCompanyHistoryJobs", {
      userId: user["id"],
      fetchedJobsIdList,
      filters: appliedFilters,
    });

    return jobs;
  }
}

export async function updateJobInstructions(jobId, instruction) {
  const jobQuery = new Parse.Query(JobModel);
  jobQuery.equalTo("objectId", jobId);

  const job = await jobQuery.first();

  job?.set("specialInstruction", instruction);

  await job?.save();

  return true;
}

export async function refreshJobs(opts?: Opts<JobModel>) {
  let query = new Parse.Query(JobModel);

  let nextQueryInstance = opts?.filter?.(query);

  query = nextQueryInstance ? nextQueryInstance : query;

  const jobs = await query.find();
  globalStore.update((s) => {
    s.jobsUpcoming = listToDictionary(jobs, "id");
  });
}

export function useJob(id: string): JobModel | undefined {
  return globalStore.useState((s) => s.jobsAll[id]);
}

export async function refreshJob(id: string, company: CompanyModel): Promise<void> {
  const jobQuery = new Parse.Query(JobModel);
  jobQuery.equalTo("objectId", id);
  jobQuery.equalTo("company", company);
  jobQuery.includeAll();

  const job = await jobQuery.first();

  if (job) {
    globalStore.update((s) => {
      s.jobsAll[job.id] = job;
    });
  }
}

export async function getJob(id: string, company: any) {
  const jobQuery = new Parse.Query(JobModel);
  jobQuery.equalTo("objectId", id);
  jobQuery.equalTo("company", company);
  jobQuery.include("company");
  jobQuery.include("preferredWorker");

  return await jobQuery.first();
}

const parseStartDateTime = (jobDate) => {
  let startTime = jobDate.startTime
  const split = startTime.split(":");

  if (startTime.includes("PM")) {
    const hour = split[0] == "12" ? "12" : Number(split[0]) + 12;

    startTime = `${hour}:${split[1]}`;
  }

  if (startTime.includes("AM")) {
    const hour = split[0] == "12" ? "00" : split[0];

    startTime = `${hour}:${split[1]}`;
  }

  const startDateTime = moment(new Date(`${jobDate.date.toISOString().split("T")[0]}T${startTime.replace("AM", "").replace("PM", "")}`)).toDate();

  return startDateTime
}
export function toServerData(
  formState: any,
  companyId: string,
  totals: any
): { job: any; forklifts: any[]; jobDates: { date: Date; duration: number; startTime: string }[]; totals: any } {
  const liftWeight = formState.liftWeight.replace(_t("up_to"), "").replace(_t("lbs"), "").trim();

  const languages = formState.languages;

  const job: any = {
    companyId,
    province: formState.province,
    city: formState.city,
    address: formState.address,
    postalCode: formState.postalCode,
    mainTask: formState.otherTask && (formState.mainTask.includes("Other") || formState.mainTask.includes("Autre")) ? formState.otherTask : formState.mainTask,
    languages: languages && languages.filter((it) => it),
    specialInstruction: formState.specialInstruction,
    specialInstructionFR: formState.specialInstructionFR,
    workersCount: +formState.workersCount,
    hasForkliftOption: formState.hasForkliftOption,
    liftWeight: parseInt(liftWeight),
    requireWarehouseExperience: formState.requireWarehouseExperience === "Required",
    additionalEmail: formState.additionalEmail,
    preferredWorker: formState.preferredWorker != "0" ? formState.preferredWorker : null,
    details: formState.details,
    detailsFR: formState.detailsFR,
  };

  console.log(formState.preferredWorker);

  const jobDates = (formState.dates || []).map((jobDate) => {
    return {
      date: jobDate.date,
      duration: Number(jobDate.duration),
      startTime: jobDate.startTime,
    };
  });

  try {
    const firstDate = jobDates.sort((a, b) => a.date - b.date)[0];
    const startDate = parseStartDateTime(firstDate)
    job.startDate = startDate;

    // update the job dates date
    jobDates.forEach(jobDate => {
      jobDate.date = parseStartDateTime(jobDate)
    })

    console.log("job", job);
  } catch (err) {
    console.log(err);
  }

  const forklifts = Object.keys(formState.forklift || {}).filter((key) => formState.forklift[key]);

  return { forklifts, job, jobDates, totals };
}

export async function refreshForkLifts(opts?: Opts<ForkliftModel>) {
  let query = new Parse.Query(ForkliftModel);

  let nextQueryInstance = opts?.filter?.(query);

  query = nextQueryInstance ? nextQueryInstance : query;

  const forklifts = await query.find();

  globalStore.update((s) => {
    s.forklifts = listToDictionary(forklifts, "id");
  });
}

export async function getJobDates(job) {
  const query = new Parse.Query(Parse.Object.extend("JobDate"));
  query.equalTo("job", job);
  query.ascending("date");

  const results = await query.find();

  return results;
}

export async function getTaxRate(province) {
  if (province) {
    const query = new Parse.Query(Parse.Object.extend("Taxes"));

    const results = await query.find();

    for (const tax of results) {
      if (tax.get("province").toLowerCase() == province.toLowerCase()) {
        return tax.get("taxRate");
      }
    }
  }

  return 0;
}

export async function createIssue(job, user, text) {
  const Issue = Parse.Object.extend("Issue");
  const issue = new Issue();

  issue.set("job", job);
  issue.set("user", user);
  issue.set("descriptionText", text);

  await issue.save();
}

export async function createReview(job, user, rating, text) {
  const Review = Parse.Object.extend("Review");
  const review = new Review();

  review.set("job", job);
  review.set("user", user);
  review.set("rating", rating);
  review.set("descriptionText", text);

  await review.save();

  job.set("workerRating", rating);
  await job.save();
}

export async function createSystemReview(company, rating, text, currentReview) {
  const Review = Parse.Object.extend("ReviewSystem");
  const review = currentReview ? currentReview : new Review();

  review.set("company", company);
  review.set("rating", rating);
  review.set("descriptionText", text);

  await review.save();
}

export async function createWorkerComment(company, workerId, commentText) {
  const Comment = Parse.Object.extend("WorkerComment");
  const comment = new Comment();

  comment.set("company", company);
  comment.set("worker", Parse.User.createWithoutData(workerId));
  comment.set("comment", commentText);

  await comment.save();
}

export async function alreadyRateWorker(job, user) {
  const query = new Parse.Query(Parse.Object.extend("ReviewWorker"));
  query.equalTo("job", job);
  query.equalTo("worker", user);

  const find = await query.first();

  return find != null && find != undefined;
}

export async function fetchPastWorkers() {
  return Parse.Cloud.run("getPastWorkers")
}

export async function fetchRecentWorkers(company) {
  const jobs = await new Parse.Query(JobModel)
    .descending("startDate")
    .equalTo("company", company)
    .containedIn("status", ["confirmed", "confirmedWithCancelledDays"])
    .include("worker")
    .limit(5000)
    .find();

  const workers: any[] = [
    {
      id: "0",
      name: _t("none_selected"),
    },
  ];

  for (const worker of jobs.map((job) => job.get("worker"))) {
    const identity = await worker.get("workerIdentity").fetch();
    const isAdded = workers.find((v) => v.id == worker.id) != null;

    if (!isAdded) {
      workers.push({
        id: worker.id,
        name: `${identity.get("firstName")} ${identity.get("lastName")}`,
      });
    }
  }

  console.log(workers);

  return workers;
}

export async function hasCompletedNotReviewedJobs(company) {
  const jobQuery = new Parse.Query(JobModel);
  jobQuery.equalTo("company", company);
  jobQuery.equalTo("isFinished", true);
  jobQuery.containedIn("status", ["confirmed", "confirmedWithCancelledDays"]);
  jobQuery.doesNotExist("workerRating");

  const jobs = await jobQuery.find();

  return jobs.length > 0;
}

export async function getSystemReview(company) {
  const query = new Parse.Query(Parse.Object.extend("ReviewSystem"));
  query.equalTo("company", company);

  const find = await query.first();

  return find;
}

export async function cancelJob(jobId, user) {
  await Parse.Cloud.run("cancelJobCompany", {
    jobId: jobId,
    userId: user.id,
  });
}

export async function isWorkerInfoAvailable(jobId) {
  return await Parse.Cloud.run("isWorkerInfoAvailable", {
    jobId: jobId,
  });
}

export async function isJobLateCancellation(jobId) {
  return await Parse.Cloud.run("isJobLateCancellation", {
    jobId: jobId,
  });
}

export async function isCancelJobAvailable(jobId) {
  return await Parse.Cloud.run("isCancelJobAvailable", {
    jobId: jobId,
  });
}

export async function getTranslation(text: string, sourceLang: string, targetLang: string): Promise<string> {
  return await Parse.Cloud.run("translate", {
    text,
    sourceLang,
    targetLang
  })
}

export function useForklifts() {
  return {
    forklifts: globalStore.useState((s) => dictionaryToList(s.forklifts)),
  };
}
