import React, {useState} from "react"
import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";

export type ModalProps = {
    open: boolean,
    title: string,
    children:  any,
    controlLeft?: any,
    controlRight?: any
};

export function Modal({open, title, children, controlLeft = null, controlRight = null}: ModalProps) {
    return (

        <Dialog open={Boolean(open)} fullWidth={true} maxWidth={"xs"}>
            <DialogTitle>
                <div className="l-flex-between" style={{fontSize: "15px"}}>
                    <div style={{width: "25%", flexShrink: 1}}>{controlLeft}</div>
                    <div style={{width: "50%", flexShrink: 1, flexGrow: 1, textAlign: "center"}}><strong>{title}</strong></div>
                    <div style={{width: "25%", flexShrink: 1, textAlign: "right"}}>{controlRight}</div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div style={{fontSize: "14px", textAlign: "center", color: "#4F4F4F"}}>{children}</div>
            </DialogContent>
        </Dialog>
    )
}

export function useModal(initialOpen?:boolean): { close: () => void; open: () => void; modalProps: { open: boolean } } {
    const [isOpen, setOpen] = useState(Boolean(initialOpen));

    const close = () => {
        setOpen(false);
    };

    const open = () => {
        setOpen(true);
    }

    return {modalProps: {open: isOpen}, close, open}
}
