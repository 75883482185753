export default {
  available_full_time: "Je cherche à travailler à temps plein",
  available_part_time: "Je cherche à travailler à temps partiel",
  rating_sent: "Évaluation envoyée avec succès!",
  issue_reported: "Problème signalé avec succès!",
  create_a_new_job: "Créez un nouvel emploi",
  about_your_company: "À propos de votre compagnie",
  contact_us: "Contactez nous",
  rate_us: "Évaluez notre service",
  terms_and_conditions: "Termes et conditions",
  privacy_policy: "Politique de confidentialité",
  log_out: "Déconnexion",
  no: "Non",
  yes: "Oui",
  you_will_be_disconnect_from_your_account: "Vous serez déconnecté de votre compte. ",
  cancel: "Annuler",
  send: "Envoyer",
  describe_your_issue_to_the_admin: "Décrivez votre problème à l'administration",
  if_you_need_assistance_call_us: "Si vous avez besoin d'aide, contactez nous au 1(844) 428-5294",
  monday_to_friday: "Lundi au vendredi, 9:00AM à 5:00PM. ",
  save: "Sauvegarder",
  having_an_issue: "Un problème à signaler?",
  how_is_your_experience: "Décrivez nous votre expérience avec l'application.",
  let_us_know_if_we_should_improve_things: "Dites nous comment nous pouvons nous améliorer.",
  unexpected_error_while_comunicating: "Érreure non-anticipée durant la communication avec le serveur, veuillez réessayer ou contactez nous directement.",
  check_your_credit_card_information: "Vérifiez votre l'information de carte de crédit.",
  not_found: "Non trouvée",
  pending: "En attente",
  confirmed: "Confirmé",
  worker_cancelled: "Travailleur(euse) a annulé(e)",
  worker_cancelled_late: "Travailleur(euse) a annulé(e) tard",
  you_cancelled: "Vous avez annulé",
  you_cancelled_late: "Vous avez annulé tard",
  confirmed_but_contains_cancelled_days: "Confirmé mais contient des jours annulés",
  card_number: "Numéro de carte",
  name_of_card_holder: "nom du titulaire de la carte",
  expiration_date: "Date d'expiration (MM/AA)",
  cvv_code: "Code CVV",
  shipping: "Expédition",
  receiving: "Réception",
  order_picking: "Préparation de commandes",
  other: "Autre",
  critical_error_happened: "Erreure Critique s'est déroulée. Contactez nous immédiatement pour annuler la transaction sur la carte de crédit.",
  required: "Requis",
  optional: "Optionnel",
  how_many_workers_do_you_need: "Combien de travailleur(euse)(s) aurez vous besoin?",
  numbers_of_workers: "Nombre de travailleur(euse)",
  when_is_the_job: "Quand se déroule l'emploi?",
  selected_dates: "Dates selectionnées",
  please_fill_out_the_starting_time:
    "Veuillez completer l'heure de début et le nombre d'heures pour chaque date selectionnée.  C'est votre résponsabilité d'être en conformité avec les",
  cnesst_rules: "règles de la CNESST.",
  starts_at: "Débute à",
  duration_hrs: "Durée (nb d'heures)",
  copy_this_setup_for_all_dates: "Copiez l'horaire pour le reste des dates ",
  where_is_the_job: "Où se déroule l'emploi?",
  address: "Adresse",
  city: "Ville",
  postal_code: "Code postal",
  province: "Province",
  we_could_not_find_that_address: "Nous n'avons pas trouvé cet adresse",
  what_is_the_job: "Quel est l'emploi?",
  main_task: "Tâche principale",
  please_specify_the_main_task: "Veuillez spécifé la tâche principale.",
  up_to: "Jusqu'à",
  lbs: "lbs",
  which_competencies_are_requested: "Quelles compétences sont demandées?",
  warehouse_experience: "Éxpérience en entrepôt",
  free_options: "Options gratuites",
  forklift_experience: "Éxpérience en conduite de chariots élévateurs (optionnel)",
  plus_3_for_selected_options: " / heure pour 1, 2 ou 3 option(s) sélectionnée(s):",
  required_spoken_languages: "Langue(s) parlée(s) requis. (optionnel)",
  english: "Anglais",
  french: "Français",
  special_instructions_for_worker: "Instructions spéciales à mentionner? (optionnel)",
  this_will_only_be_seen_by_the_worker:
    "Ceci sera seulement visible au travailleur(euse) qui confirme leur présense à l'emploi. Si vous avez des instructions spéciales, mentionnez les içi. Celles-ci peuvent être changer, si nécéssaire.",
  please_provide_instructions_and_information:
    "Veuillez inclure des instructions et informations sur l'entrée à l'immeuble (exemple: stationnement, porte à utiliser, code d'entrée…) ainsi que toutes commodités disponibles (café gratuite, capacité d'acheter de la nourriture sur place/restaurants dans la région cantine…)",
  payment_method: "Méthode de paiment",
  select_a_payment_method_to_publish_the_job:
    "Selectionnez la méthode de paiment pour publier votre emploi. Le montant sera réservé, et le paiment sera traité quand un(e) travailleur(euse) confirme sa présence.",
  use_a_credit_card_linked_to_my_account: "Utilisez la carte de crédit liée à mon compte",
  no_credit_card_linked_to_my_account: "Pas de  carte de crédit liée à votre compte",
  add_a_credit_card: "Ajouter une carte de crédit ",
  job: "Emploi",
  workers: "Travailleur(euse)s",
  basic_hourly_rate: "Taux horaire",
  forklift_option: "Option Chariot élévateur ",
  subtotal: "Sous-total",
  taxes: "Taxes",
  total: "Total",
  continue: "Continuer",
  publish_the_job: "Publier l'emploi",
  as_you_selected_several_workers: "Puisque vous avez sélectionné plusieurs travailleurs, plusieurs emplois seront publiés et payés individuellement.",
  some_information_highlighted_in_red_are_missing: "Certaines informations surlignées en rouge sont manquantes ou incorrectes.",
  please_wait: "Veuillez patienter ...",
  publish_the_job_question: "Publier l'emploi?",
  please_check_again: "Veuillez vérifier à nouveau.",
  workers_will_be_notified_payment: "Les travailleur(euse)s seront notifié(e)s Le paiement sera capturé lorsqu'un(e) travailleur(euse) est confirmé(e).",
  workers_will_be_notified_invoice: "Les travailleur(euse)s seront notifié(e)s. Vous recevrez une facture lorsqu'un(e) travailleur(euse) est confirmé(e).",
  job_published: "Emploi publié!",
  you_will_get_notified_when_a_worker_takes_the_job: "Vous serez averti lorsqu'un(e) travailleur(euse) confirme sa présence à l'emploi.",
  please_wait_we_are_processing_your_job: "Veuillez patienter, nous traitons votre (vos) emploi(s)",
  please_wait_loading_your_job: "Veuillez patienter, chargement de votre emploi ...",
  food: "Alimentation",
  healthcare_pharma: "Santé/pharma",
  manufacturing: "Fabrication",
  logistics: "Logistique",
  fashion: "Mode",
  ecommerce: "Commerce électronique",
  technology: "Technologie",
  unexpected_error_please_try_again: "Erreur inattendue, veuillez réessayer",
  information_visible_only_to_the_admin: "Informations visibles uniquement par l'administrateur",
  contact_first_name: "Prénom du contact",
  contact_last_name: "Nom de famille du contact",
  email_address: "Adresse e-mail",
  information_visible_to_workers: "Informations visibles par les travailleur(euse)s",
  company_logo: "Logo de l'entreprise (facultatif)",
  edit_company_logo: "Modifier le logo de l'entreprise",
  add_company_logo: "Ajouter le logo de l'entreprise",
  jpg_gif_or_png: "JPG, GIF ou PNG. Taille maximale de 5 Mo.",
  company_name: "Nom de l'entreprise",
  website: "Site Web (facultatif)",
  phone_number: "Numéro de téléphone",
  phone_number2: "Numéro de téléphone 2 (facultatif)",
  industry: "Secteur d'activité",
  please_specify_the_industry_type: "Veuillez spécifier le type d'industrie (30 caractères maximum)",
  approximate_size_of_warehouse: "Taille approximative de l'entrepôt (en pieds carrés)",
  number_of_employees: "Nombre d'employés",
  small_size_1_to_99: "1-99 petite",
  small_size_100_to_499: "100-499 moyenne",
  small_size_500_plus: "500+ grande",
  training_health_safety_process: "Processus de formation santé et sécurité",
  please_provide_further_information_on_your_process:
    "Veuillez fournir plus d'informations sur votre processus et la formation en matière de santé et sécurité pour aider votre travailleur(euse) à être préparé à quoi il/elle doits'attendre! par exemple: 2 heures de formation sur les processus, 30 minutes de formation sur la sécurité / les protocoles",
  about_your_company_optional: "À propos de votre entreprise (facultatif)",
  tell_the_workers_what_your_company_is_all_about:
    "Dites aux travailleurs ce qu'est votre entreprise. Copiez certaines informations de votre site Web oudites- leur pourquoi vous êtes un excellent lieu de travail!",
  user_not_found: "Utilisateur non trouvé",
  unknown_error_happened: "Une erreur inconnue s'est produite",
  we_sent_you_an_email_to_reset_your_password: "Nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe",
  reset_your_password: "Réinitialiser votre mot de passe",
  send_recovery_email: "Envoyer un e-mail de récupération",
  invalid_email_use_other_email_for_recovering_the_password: "E-mail invalide, utiliser un autre e-mail pour récupérer le mot de passe",
  a_recovery_email_has_been_sent: "Un e-mail de récupération a été envoyé",
  error: "Erreur",
  success: "Réussite",
  failed_to_cancel_this_job: "L'annulation de l'emploi a échoué.",
  pre_authorization_created: "Pré-autorisation créée.",
  pre_authorization_cancelled: "Pré-autorisation annulée.",
  pre_authorization_captured: "Pré-autorisation capturée:",
  invoice_not_sent_yet: "Facture n'est pas envoyée encore:",
  invoice_cancelled: "Facture annulée:",
  invoice_sent: "Facture envoyée:",
  instructions_updated_successfully: "la mises à jour des instructions complétée!",
  loading: "Chargement ...",
  status: "Statut",
  your_worker: "Votre travailleur(euse)",
  rate_your_worker: "Evaluez votre travailleur(euse)",
  duplicate_this_job: "Dupliquer cet emploi",
  report_an_issue: "Signaler un problème",
  cancel_this_job: "Annuler cet emploi",
  starting_date: "Date de début",
  end_date: "Date de fin",
  working_days: "Jours ouvrables",
  total_hours: "Heures totales",
  warehouse_address: "Adresse de l'entrepôt",
  task: "Tâche",
  weight_to_lift: "Poids à lever",
  forklift: "Chariot élévateur",
  required_spoken_language: "Langue parlée requise",
  instructions_for_worker: "Instructions pour le/la travailleur(euse)",
  edit: "Modifier",
  your_instructions_for_the_worker: "Vos instructions pour le/la travailleur(euse)",
  schedule_details: "Détails du calendrier",
  date: "DATE",
  start: "DEBUT",
  duration: "DURÉE",
  price_and_payment: "Prix et paiement",
  original_price: "PRIX ORIGINAL",
  original_total: "Total original",
  updated_price_based_on_cancellations: "MISE À JOUR DU PRIX APRÈS ANNULATION (S)",
  cancellation_fees: "Frais d'annulation",
  if_you_cancelled_late_some_dates:
    "Si vous annulez quelques jours en retards, vous serez chargés le moindre des deux de 25% du prix des jours annulées en retards ou $150.",
  updated_total: "Mise à jour du total",
  refund: "Remboursement",
  payment: "PAIEMENT",
  credit_card_ending_with: "Carte de crédit se terminant par ",
  invoice: "Facture",
  payment_status: "Status de paiement",
  refund_sent: "Remboursement envoyé:",
  cancel_this_job_question: "Annuler cet emploi?",
  if_you_cancel_now_you_will_get_cancellation_fees: "Si vous annulez maintenant, vous recevrez des frais d'annulation de",
  if_you_cancel_now_you_will_get_100_refund: "Si vous annulez maintenant, vous obtiendrez un remboursement à 100%.",
  your_comment_optional: "Votre commentaire (facultatif, le/la travailleur(euse) ne le verra pas)",
  edit_instructions_for_worker: "Modifier les instructions pour le/la travailleur(euse)",
  job_cancelled: "Emploi annulé!",
  job_successfully_cancelled: "Emploi annulé avec succès!",
  we_are_processing_your_request: "Nous traitons votre demande ...",
  tap_on_the_button_create_a_new_job: "Appuyez sur le bouton «Créer un nouvel emploi» pour commencer.",
  check_your_email_and_password_and_try_again: "Vérifiez votre adresse e-mail et votre mot de passe et réessayez",
  log_into_your_existing_company_account: "Connectez-vous à votre compte d'entreprise existant",
  password: "Mot de passe",
  remember_me: "Se rappeler de moi",
  password_forgotten: "Mot de passe oublié?",
  login: "Connexion",
  dont_have_an_account: "Vous n'avez pas de compte?",
  sign_up: "Inscrivez-vous",
  this_email_is_already_registered: "Cet e-mail est déjà enregistré",
  something_wrong_happened: "Une erreur s'est produite, réessayez plus tard ou contactez notre support à la clientèle",
  create_your_company_account: "Créer votre compte d'entreprise",
  by_clicking_on_sign_up: "En cliquant sur «S'inscrire», j'accepte les",
  and: "et",
  already_have_an_account_question: "Avez-vous déjà un compte?",
  log_in: "Connexion",
  we_have_an_worker_for_you: "Nous avons un(e) travailleur(euse) pour vous.",
  we_have_not_found_a_worker_refund_100: "Nous n'avons pas trouvé un(e) travailleur(euse) pour cet emploi. Vous recevrez un remboursement complet.",
  we_are_looking_for_a_worker: "Nous sommes à la recherche d'un(e) travailleur(euse).",
  the_worker_is_no_longer_available: "Le/la travailleur(euse) n'est plus disponible pour prendre l'emploi. Vous recevrez un remboursement complet.",
  you_cancelled_this_job_refund_100: "Vous avez annulé l'emploi. Vous recevrez un remboursement complet.",
  you_cancelled_this_job_under: "Vous avez annulé l'emploi moins de",
  hours_before_it_starts: "heures avant le début de l'emploi. Vous recevrez un remboursement partiel.",
  check_the_schedule_details: "Verifiez les détails sur les horaires de l'emploi.",
  other_task: "Autre Tâche",
  other_industry_type: "Autre industrie",
  lift_weight: "poids à soulever",
  required_languages: "Langues requises",
  name: "Nom",
  size: "Taille",
  training_process: "Processus de formation",
  about: "À propos",
  description: "Description",
  upcoming_jobs: "Emploi à venir",
  history: "Historique",
  no_cards_registered: "Pas de cartes enregistrées",
  starting_time: "Heure d'arrivée",
  days: "Jours",
  hours: "Heures",
  price: "Prix",
  worker: "Travailleur(euse)",
  upcoming: "à venir",
  jobs: "emploi",
  no_without_jobs: "Pas d'emploi",
  pallet_jack: "Transpallette à main",
  reach_truck: "Chariot retractable éléctrique",
  cherry_picker: "Nacelle élevatrice",
  counter_balance_truck: "Chariot élévateur à fourche",
  hourly_rate_offered_to_workers: "Taux horaire offert aux travailleurs (Min. [hourlyRate])",
  hourly_rate_service_fee: "Un frais de service de [serviceFee]% sera ajouté au total.",
  additional_email_notify_title:
    "Si vous souhaitez que quelqu'un d'autre reçoive des notifications pour cette offre d'emploi, veuillez inscrire son email ci-dessous. (Optionnel)",
  including_x_markup_fee: "Incluant le frais de service de [serviceFee]%",
  want_to_rehire_a_past_worker: "Vous souhaitez réembaucher une personne spécifique?",
  rehire_past_worker_text:
    "Sélectionnez un travailleur dans le menu déroulant et il lui sera envoyé exclusivement. Idéalement, vous l'aurez prévenu auparavant !",
  if_you_want_rehire_past_worker_keep_above: "Si vous souhaitez réembaucher une personne spécifique, selectionner « 1 » ci-dessus.",
  none_selected: "Aucun nom sélectionné",
  please_provide_more_details_about_this_job_optional_label: "Veuillez fournir plus de détails sur cette offre (facultatif)",
  please_provide_more_details_about_this_job_optional_placeholder: "Veuillez indiquer une briève description de la tâche principale.",
  task_description: "Description de la tâche",
  hourly_cost: "Coût horaire",
  review_past_workers: "Evaluer les derniers travailleur(euse)s",
  review_past_workers_text:
    "Pour une meilleure expérience, veuillez évaluer vos travailleur(euses) dans l'historique d'emploi et inscrivez vos commentaires! Ceci n'est pas visible au travailleur(euse), mais peut être utiliser pour coacher.",
  later: "Pas maintenant",
  rate_them_now: "Evaluer maintenant",
  rate_worker: "Evaluer le travailleur(euse)s",
  rate_worker_button: "Evaluer",
  load_more: "Charger plus",
  looking_full_time: "Je cherche à travailler à temps plein.",
  looking_part_time: "Je cherche à travailler à temps partiel.",
  completed_hours: "Heures Effectuées",
  your_average_rating: "Evaluation en moyenne",
  your_notes_about_this_worker: "Vos commentaires",
  type_your_notes_here: "Tapez vos notes içi. Seulement vous et l'administrateur aura visibilité.",
  add: "Ajouter",
  delete: "Effacer",
  past_workers: "Ancien(ne) travailleur(euse)",
  workers_who_already_worked_for_you: " Travailleur(euse)s qui ont déjà travaillé chez vous.",
  notes_saved: "commentaires enregistrées !",
  comment_deleted: "Commentaire supprimé !",
  english_version: "Version Anglaise",
  french_version: "Version Française",
  translate_from_english: "Traduire de l'anglais",
  translate_from_french: "Traduire du français",
  filter_workers: "Travailleur(euse)s",
  add_filters: "Ajouter des filtres",
  filters: "Filtrer",
  job_status: "Status",
  filter_task: "Tâche",
  apply: "Appliquer",
  reset: "Réinitialiser",
  question_split_job: "Êtes-vous flexible à avoir plusieurs personnes combler ce projet?",
  yes_split_job: 'Oui: une offre par jour, par personne sera affichée. (ex: 3 personne pour 5 jours affichera 15 offres)',
  no_split_job: 'Non: nous voulons avoir une personne pour le projet au complet.'
};
