import React from "react";
import { Form, useForms } from "../components/controls/form";
import { Field } from "../components/controls/field";

import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { routes } from "../../app/routes";
import Joi from "joi";
import { inlineErr } from "../../app/utils/error-handler-util";
import { resetPassword } from "../../app/services/user-service";
import { ReactComponent as LogoIcon } from "../../assets/icons/logo.svg";
import { Button } from "../components/controls/button";
import warehouseImg from "../../assets/images/Warehouse management system 2 1.png";
import { useHiddenHeader } from "../../app/services/ui-service";
import { Modal } from "../components/controls/modal";
import { _t } from "../../app/translate/translate-wrapper";

const schema = Joi.object({
  email: Joi.string().email({ tlds: false }).required(),
});

export function ForgotPasswordPage() {
  const navigate = useNavigate();

  const { onSubmit, genericFieldProps } = useForms(schema, {}, async (formIsValid, formState) => {
    if (formIsValid) {
      const [_, err] = await inlineErr(resetPassword(formState.email));

      if (err) {
        if ((err as any)?.type == "userNotFound") {
          return alert(_t("user_not_found"));
        } else {
          alert(`${_t("unknown_error_happened")} [${err.message}]`);
        }
      } else {
        alert(_t("we_sent_you_an_email_to_reset_your_password"));
        navigate(routes.root);
      }
    }
  });

  useHiddenHeader();

  return (
    <>
      <div className="l-flex-between" style={{ overflow: "hidden" }}>
        <Form style={{ height: "100vh", flexGrow: 1, alignSelf: "start", overflow: "auto", position: "relative", zIndex: 1 }} onSubmit={onSubmit}>
          <div className="l-content" style={{ maxWidth: "475px" }}>
            <div style={{ marginTop: "62px" }}>
              <Link to={routes.root}>
                <LogoIcon />
              </Link>
              <h1 className="l-text-alternate-family" style={{ marginTop: "50px", marginBottom: "30px", lineHeight: "45px" }}>
                {_t("reset_your_password")}
              </h1>
            </div>

            <hr style={{ margin: "30px 0" }} />

            <Field className="l-full-width" name="email" label={_t("email_address")} variant="outlined" {...genericFieldProps} />

            <Button className="l-full-width" valid={true} style={{ marginTop: "43px" }}>
              {_t("send_recovery_email")}
            </Button>
          </div>
        </Form>

        <div className="l-flex-center l-mob-hide" style={{ background: "#28588C", height: "100vh", zIndex: 2, position: "relative", flexShrink: 1 }}>
          <img src={warehouseImg} style={{ position: "relative", left: "-75px" }} />
        </div>
      </div>

      <Modal
        title={_t("error")}
        controlRight={
          <div className="l-clickable" style={{ color: "#325E8C" }}>
            <strong>OK</strong>
          </div>
        }
        open={false}
      >
        {_t("invalid_email_use_other_email_for_recovering_the_password")}
      </Modal>

      <Modal
        title={_t("success")}
        controlRight={
          <div className="l-clickable" style={{ color: "#325E8C" }}>
            <strong>OK</strong>
          </div>
        }
        open={false}
      >
        {_t("a_recovery_email_has_been_sent")}
      </Modal>
    </>
  );
}
