import { Field } from "../../components/controls/field";
import React from "react";
import { StripeInput } from "../../components/controls/stripe-input";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { _t } from "../../../app/translate/translate-wrapper";
const buildStripeProps = (stripeElmType) => ({
  inputComponent: StripeInput,
  inputProps: {
    component: stripeElmType,
  },
});

export function CreditCardForm({ genericFieldProps }) {
  return (
    <div>
      <div className="force-placeholder-stripe">
        <Field
          className="l-full-width"
          name="credit card"
          label={_t("card_number")}
          variant="outlined"
          containerStyle={{ marginTop: "20px" }}
          InputProps={buildStripeProps(CardNumberElement)}
        />
        <Field
          className="l-full-width"
          name="fullName"
          label={_t("name_of_card_holder")}
          variant="outlined"
          containerStyle={{ marginTop: "0px" }}
          {...genericFieldProps}
        />

        <div className="l-row">
          <div className="l-mob-6-12 l-desk-6-12">
            <Field
              className="l-full-width"
              name="credit card"
              label={_t("expiration_date")}
              variant="outlined"
              containerStyle={{ marginTop: "0px" }}
              InputProps={buildStripeProps(CardExpiryElement)}
            />
          </div>
          <div className="l-mob-6-12 l-desk-6-12">
            <Field
              className="l-full-width"
              name="credit card"
              label={_t("cvv_code")}
              variant="outlined"
              containerStyle={{ marginTop: "0px" }}
              InputProps={buildStripeProps(CardCvcElement)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
