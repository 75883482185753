import { ColDef } from "@material-ui/data-grid";
import React, { CSSProperties, useState } from "react";
import { ReactComponent as ArrowDownIcon } from "../../../assets/icons/arrow-down.svg";

export type Column = any;

const stickyStyle = { position: "sticky", top: 0, background: "#fff" } as any;

export type TableOderingOptsType = {
  [field: string]: "asc" | "desc";
};

type tableProps = {
  itemKey: string;
  rows?: any[];
  ordering?: TableOderingOptsType;
  columns: Column[];
  sidePadding?: "string";
  minWidth?: "string";
  headerHeight?: number;
  onItemClick?: (row) => void;
  onHeaderItemClick?: (columns: Column) => void;
  style?: CSSProperties;
};

export function Table(props: tableProps) {
  const ordering = props.ordering || {};
  const itemKey = props.itemKey;
  const rows = props.rows || ([] as any[]);
  const columns = props.columns;
  const sidePadding = props.sidePadding;
  const minWidth = props.minWidth;
  const rowHeight = "" as string | number;
  const headerHeight = 0;
  const itemOnClick = props.onItemClick;
  const onHeaderItemClick = undefined as unknown as (columns: Column) => void;

  const arrow = {
    asc: (
      <span style={{ display: "inline-block" }}>
        <ArrowDownIcon />
      </span>
    ),
    desc: (
      <span style={{ display: "inline-block", transform: "scale(1,-1)" }}>
        <ArrowDownIcon />
      </span>
    ),
  };

  return (
    <table className="table" style={{ minWidth: `${minWidth}px`, ...props.style }}>
      <colgroup>
        {columns.map((it) => (
          <col style={{ height: `${rowHeight}px`, width: `${it.width}px` }} key={it.headerName} />
        ))}
      </colgroup>
      <thead>
        <tr>
          {columns.map((it) => {
            const direction = ordering[it.field] || "asc";

            return (
              <th
                style={{ height: `${headerHeight || rowHeight}px` }}
                className={`table--cell table--header ${onHeaderItemClick && "l-clickable"}`}
                key={it.headerName}
                onClick={() => onHeaderItemClick && onHeaderItemClick(it)}
              >
                {it.headerName}
                {it.headerName && it.sortable && arrow[direction]}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {rows.map((it) => (
          <tr style={{ cursor: "pointer" }} key={"row-" + it[itemKey]}>
            {columns.map((column) =>
              column.hasCustomClick ? (
                <td className="table--cell" key={it[column.field]}>
                  {it[column.field]}
                </td>
              ) : (
                <td onClick={() => itemOnClick?.(it)} className="table--cell" key={it[column.field]}>
                  {it[column.field]}
                </td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function useTableOrderingOpts(defaultOrdering: TableOderingOptsType): {
  ordering: TableOderingOptsType;
  tableProps: { onHeaderItemClick: (headerItem: Column) => void; ordering: TableOderingOptsType };
  applyOrdering: (ordering: TableOderingOptsType, query: Parse.Query) => void;
} {
  const [ordering, setOrdering] = useState(defaultOrdering as TableOderingOptsType);

  const onHeaderItemClick = (headerItem: Column) => {
    const field = headerItem.field;
    const previousValues = ordering[field] || "asc";
    const orderingDraft = Object.keys(ordering).reduce((acc, orderingKey) => {
      if (orderingKey === field) return acc;
      return { ...acc, [orderingKey]: ordering[orderingKey] };
    }, {});
    setOrdering({ [field]: previousValues === "asc" ? "desc" : "asc", ...orderingDraft } as TableOderingOptsType);
  };

  const applyOrdering = (ordering: TableOderingOptsType, query: Parse.Query) => {
    const orderingKeys = Object.keys(ordering);
    if (orderingKeys.length > 0) {
      orderingKeys.forEach((orderKey) => {
        if (ordering[orderKey] === "asc") {
          query.addAscending(orderKey);
        } else {
          query.addDescending(orderKey);
        }
      });
    }
  };

  return { ordering, applyOrdering, tableProps: { onHeaderItemClick, ordering } };
}
