import moment from "moment";
import english from "./english";
import french from "./french";

export enum LanguageOptions {
  EN = "en",
  FR = "fr",
}

const languageKey = "language";

export function saveLanguagePreference() {
  const newPreference = getSaveLanguagePreference() == LanguageOptions.EN ? LanguageOptions.FR : LanguageOptions.EN;

  localStorage.setItem(languageKey, newPreference);

  window.location.reload();
}

export function getSaveLanguagePreference() {
  return localStorage.getItem(languageKey) || LanguageOptions.EN;
}

export function _t(translateKey: string): string {
  return getSaveLanguagePreference() == LanguageOptions.EN ? english[translateKey] : french[translateKey];
}

export function translateJobTask(task: string): string {
  const key = task == "Order Picking" ? "order_picking" : task.toLowerCase();
  const translated = _t(key);

  return translated || task;
}

export function translateForkliftName(name: string): string {
  const key = name == "Counter Balance Truck" ? "counter_balance_truck" : name.replace(" ", "_").toLowerCase();
  const translated = _t(key);

  return translated || name;
}

export function dateToLocalizedString(date: Date): string {
  const locale = getSaveLanguagePreference() == LanguageOptions.EN ? "en-US" : "fr-FR";

  return date.toLocaleDateString(locale, { weekday: "long" });
}

export function calendarLocalizedWeekDays(): string[] {
  return getSaveLanguagePreference() == LanguageOptions.EN ? [] : ["DIM", "LUN", "MAR", "MER", "JEU", "VEN", "SAM"];
}

export function calendarLocalizedMonths(): string[] {
  const frenchMonths = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];

  return getSaveLanguagePreference() == LanguageOptions.EN ? [] : frenchMonths;
}

export function getDaysJsLocale() {
  return require(`dayjs/locale/${getSaveLanguagePreference().toLocaleLowerCase()}.js`);
}

export function formatDateToLocale(date: Date) {
  const formated = moment(date).format("ddd DD/MM");

  if (getSaveLanguagePreference() == LanguageOptions.EN) return formated;

  if (formated.toLowerCase().includes("sun")) return formated.replace("Sun", "Dim");
  if (formated.toLowerCase().includes("mon")) return formated.replace("Mon", "Lun");
  if (formated.toLowerCase().includes("tue")) return formated.replace("Tue", "Mar");
  if (formated.toLowerCase().includes("wed")) return formated.replace("Wed", "Mer");
  if (formated.toLowerCase().includes("thu")) return formated.replace("Thu", "Jeu");
  if (formated.toLowerCase().includes("fri")) return formated.replace("Fri", "Ven");
  if (formated.toLowerCase().includes("sat")) return formated.replace("Sat", "Sam");

  return formated;
}
