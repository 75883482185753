import React, { useEffect, useState } from "react";
import Parse from "parse";
import { Calendar } from "react-multi-date-picker";

import { HeaderSubtract } from "../../components/layout/header-subtract";
import { FormControl, FormSectionTitle } from "../about-company-page";
import { Field, FieldLabel } from "../../components/controls/field";
import { FormControlLabel, MenuItem, Radio, Select } from "@material-ui/core";

import { ReactComponent as CopySetupIcon } from "../../../assets/icons/copy-setup.svg";
import { Button } from "../../components/controls/button";
import { useSearchParams } from "react-router-dom";
import { Modal, useModal } from "../../components/controls/modal";
import { useForms } from "../../components/controls/form";
import Joi from "joi";
import { ReactComponent as CloseIconImg } from "../../../assets/icons/close_icon.svg";
import dayjs from "dayjs";
import { refreshUser, useCurrentUser } from "../../../app/services/user-service";
import { getTaxRate, refreshForkLifts, toServerData, useForklifts, getJob, getJobDates, fetchRecentWorkers } from "../../../app/services/job-service";
import { inlineErr } from "../../../app/utils/error-handler-util";

import { CreditCardForm } from "./credit-card-form";
import { createPaymentIntent, useListRegisteredCards, usePaymentCommons } from "../../../app/services/payment-service";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useNavigate, useParams } from "react-router";
import { routes } from "../../../app/routes";

import TimePicker from "rc-time-picker";
import "rc-time-picker/assets/index.css";
import "./job-create-page.scss";
import { getParseConfig, numberToTime, timeToNumber, percentageCalc, formatMonetary } from "../../../app/utils/commons";

import momentTz from "moment-timezone";
import moment from "moment";
import {
  calendarLocalizedMonths,
  calendarLocalizedWeekDays,
  formatDateToLocale,
  translateForkliftName,
  translateJobTask,
  getSaveLanguagePreference,
  _t,
} from "../../../app/translate/translate-wrapper";
import { getJobDateStartDateISO } from "../../../app/utils/job-date.utils";
import { CustomField } from "../../components/controls/custom-field";
import { TranslateButton } from "../../components/translate-button";

import EN from '../../../app/translate/english'
import FR from '../../../app/translate/french'

const listProvinces = [
  "Yukon",
  "Saskatchewan",
  "Prince Edward Island",
  "Ontario",
  "Nunavut",
  "Nova Scotia",
  "Northwest Territories",
  "Newfoundland and Labrador",
  "New Brunswick",
  "Manitoba",
  "British Columbia",
  "Alberta",
  "Quebec",
];

const jobOfferOptions = ["Yes, please split the job into 1 day-jobs.", "No, we need it to be the same person for the full job offer."];
const jobOfferOptionsLabels = [_t("yes_split_job"), _t("no_split_job")];

const jobTasks = [_t("shipping"), _t("receiving"), _t("order_picking"), _t("other")];

const creditCardSchema = Joi.object({
  paymentOpt: Joi.string().required(),
}).unknown(true);

let jobSchema = Joi.object({
  workersCount: Joi.string().required(),
  dates: Joi.array()
    .items(
      Joi.object({
        date: Joi.date().required(),
        startTime: Joi.string().min(6).required(),
        duration: Joi.string().min(1).required(),
      })
    )
    .required(),
  province: Joi.string().required(),
  city: Joi.string().required(),
  address: Joi.string().required(),
  postalCode: Joi.string().min(6).max(7).required(),
  mainTask: Joi.string().required(),
  otherTask: Joi.string()
    .max(30)
    .when("mainTask", { is: _t("other"), then: Joi.required(), otherwise: Joi.optional().allow("") }),
  liftWeight: Joi.string().required(),
  forklift: Joi.object().unknown(true),
  languages: Joi.array().optional(),
  requireWarehouseExperience:  Joi.any().optional(),
  specialInstruction: Joi.any().optional(),
  specialInstructionFR: Joi.any().optional(),
  additionalEmail: Joi.any(),
  preferredWorker: Joi.any(),
  details: Joi.any().optional(),
  detailsFR: Joi.any().optional(),
  jobOfferOption: Joi.string().required(),
});

export default function JobCreatePage() {
  const [recentWorkers, setRecentWorkers] = useState<any[]>([]);

  const [minimumHourlyRate, setMinimumHourlyRate] = useState(0);

  const [forkliftCost, setForkliftCost] = useState(0);
  const [forkliftRate, setForkliftRate] = useState(0);
  const [taxRate, setTaxRate] = useState(0);
  const [markupRate, setMarkupRate] = useState(0);
  const [hourlyCost, setHourlyCost] = useState(0);
  const [hourlyRate, setHourlyRate] = useState<number | null>(0);
  const [subTotal, setSubTotal] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [totalJobsCreated, setTotalJobsCreated] = useState(1);

  const currentUser = useCurrentUser();
  const company = currentUser?.company;
  const [_isPayment, setIsPayment] = useState(false);

  const [isLatLngError, setIsLatLngError] = useState(false);
  const [latLng, setLatLng] = useState(null as any);

  const [queries] = useSearchParams();
  const { saveCreditCard } = usePaymentCommons();
  const cards = useListRegisteredCards();
  const stripe = useStripe()!;
  const forklifts = useForklifts();
  const navigate = useNavigate();

  const loadingTaxesModal = useModal();
  const paymentModal = useModal();
  const paymentInvoiceModal = useModal();
  const publishingJobsModal = useModal();
  const successModal = useModal();
  const loadingJob = useModal();
  const recheckModal = useModal();

  const params = useParams();

  const [isRatesFetched, setIsRatesFetched] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const initialFormValue = {
    paymentOpt: "2",
  };

  useEffect(() => {
    fetchData();
  }, [!isDataFetched]);

  useEffect(() => {
    getTotalHours(null);
  }, [isDataFetched]);

  useEffect(() => {
    if (cards.length > 0) {
      paymentForm.setFormValue({
        ...paymentForm,
        paymentOpt: "1",
        selectedCard: "Visa ending with " + (cards.find((it) => it.default) || cards[0]).last4,
      });
    }
  }, [cards.length]);

  useEffect(() => {
    calculateHourlyRate(hourlyRate, markupRate);
    getTotalHours(null);
  }, [hourlyCost]);

  const fetchData = async () => {
    if (!isDataFetched) {
      loadingJob.open();

      await Promise.all([updateUser(), getRates(), refreshForkLifts(), getRecentWorkers()]);

      if (params.id) {
        await getJobToDuplicate(params.id);
      }

      getTotalHours(null);

      loadingJob.close();

      setIsDataFetched(true);

      commonForm.formState.jobOfferOption = jobOfferOptions[1];

      commonForm.setFormValue({
        ...jobFormState,
      });
    }
  };

  const updateUser = async () => {
    await refreshUser();
  };

  const devPayment = Boolean(queries.get("devPayment"));
  const isPayment = _isPayment || devPayment;

  const getRecentWorkers = async () => {
    const workers = await fetchRecentWorkers(company);

    setRecentWorkers(workers);

    const jobFormState = commonForm.formState;

    jobFormState.preferredWorker = workers[0].id;

    commonForm.setFormValue({
      ...jobFormState,
    });
  };

  //@ts-ignore
  const fetchLatLng = async () => {
    const jobFormState = commonForm.formState;

    console.log("Geo localization started");

    if (!jobFormState.address || !jobFormState.postalCode || !jobFormState.city || !jobFormState.province) {
      return console.log("Geo localization stopped / invalid parameters");
    }

    const [latLngResult] = await inlineErr(
      Parse.Cloud.run("fetchLatLng", {
        address: jobFormState.address,
        postalCode: jobFormState.postalCode,
        city: jobFormState.city,
        province: jobFormState.province,
      })
    );

    if (latLngResult) {
      setIsLatLngError(false);
      setLatLng(latLngResult);

      if (company?.attributes?.hasInvoice) {
        handleInvoicePayment();
      } else {
        setIsPayment(true);
      }
    } else {
      setIsLatLngError(true);
    }
  };

  const handleInvoicePayment = () => {
    paymentInvoiceModal.open();
  };

  const splitJobInOneDay = () => {
    const jobDates = commonForm.formState.dates || [];
    const splittedSubTotal = subTotal / jobDates.length;
    const jobList: any[] = [];

    const totals = {
      subTotal: Number(splittedSubTotal.toFixed(2)),
      total: Number((splittedSubTotal + calculateTaxes(splittedSubTotal)).toFixed(2)),
      totalTax: Number(calculateTaxes(splittedSubTotal).toFixed(2)),
      hourlyRate,
      hourlyCost,
      forkliftCost,
      forkliftRate,
      totalHours,
    };

    for (let i = 0; i < jobDates.length; i++) {
      const currentDate = jobDates[i];
      const form = { ...commonForm.formState };

      form.dates = [currentDate];

      const req = toServerData(form, company?.id || "", totals);

      if (commonForm.formState.workersCount != 1) {
        req.job.preferredWorker = null;
      }

      jobList.push(req);
    }

    return jobList;
  };

  const doInvoicePayment = async () => {
    paymentInvoiceModal.close();
    publishingJobsModal.open();

    const isSplitJobInSeveralOneDay = commonForm.formState.jobOfferOption == jobOfferOptions[0];

    const totals = {
      subTotal: Number(subTotal.toFixed(2)),
      total: Number((subTotal + calculateTaxes()).toFixed(2)),
      totalTax: Number(calculateTaxes().toFixed(2)),
      hourlyRate,
      hourlyCost,
      forkliftCost,
      forkliftRate,
      totalHours,
    };

    for (let i = 0; i < commonForm.formState.workersCount; i++) {
      setTotalJobsCreated(i + 1);

      if (isSplitJobInSeveralOneDay) {
        const jobList = splitJobInOneDay();

        for (const jobReq of jobList) {
          try {
            //console.log("**** SPLIT: JOB FORM SUBMIT STATE", jobReq)

            await Parse.Cloud.run("register_payment", { jobReq, isInvoice: true, days: commonForm.formState.dates.length, splitIntoOneDayJobs: true });

            publishingJobsModal.close();
            successModal.open();
          } catch (err) {
            console.log("error", err);
            publishingJobsModal.close();
            alert(_t("critical_error_happened"));
          }
        }
      } else {
        const jobReq = toServerData(commonForm.formState, company?.id || "", totals);
        //console.log("**** NO SPLIT: JOB FORM SUBMIT STATE", jobReq)

        if (commonForm.formState.workersCount != 1) {
          jobReq.job.preferredWorker = null;
        }

        try {
          await Parse.Cloud.run("register_payment", { jobReq, isInvoice: true });

          publishingJobsModal.close();
          successModal.open();
        } catch (err) {
          console.log("error", err);
          publishingJobsModal.close();
          alert(_t("critical_error_happened"));
        }
      }
    }
  };

  const initialForm = {
    initialFormValue: {
      workersCount: "1",
    },
  };

  const handleForkliftOption = () => {
    if (jobFormState.forklift) {
      const selectedForklifts = Object.keys(jobFormState.forklift);

      let forkliftOption = false;

      selectedForklifts.map((fork) => {
        if (jobFormState.forklift[fork]) {
          const find = forklifts.forklifts.find((f) => f.id == fork);

          if (find && !forkliftOption) forkliftOption = true;
        }
      });
    }
  };

  const commonForm = useForms(jobSchema, initialForm, async (formIsValid, formState) => {
    if (formIsValid) {
      loadingTaxesModal.open();

      await fetchLatLng();

      loadingTaxesModal.close();
    } else {
      console.log(Error)
      recheckModal.open();
    }
  });

  const paymentForm = useForms(creditCardSchema, { initialFormValue }, async (formIsValid, formState: any) => {
    const isSplitJobInSeveralOneDay = commonForm.formState.jobOfferOption == jobOfferOptions[0];

    paymentModal.close();
    publishingJobsModal.open();

    let cardData;

    if (formIsValid) {
      switch (formState.paymentOpt) {
        case "1":
          const last4Match = formState.selectedCard.match(/\d{4}/);
          cardData = cards.find((it) => it.last4 === last4Match![0]);
          await Parse.Cloud.run("update_default_source", { sourceId: cardData.id });
          break;

        case "2":
          cardData = await saveCreditCard(paymentForm.formState.fullName);
          break;
      }

      const totals = {
        subTotal: Number(subTotal.toFixed(2)),
        total: Number((subTotal + calculateTaxes()).toFixed(2)),
        totalTax: Number(calculateTaxes().toFixed(2)),
        hourlyRate,
        hourlyCost,
        forkliftCost,
        forkliftRate,
        totalHours,
      };

      try {
        for (let i = 0; i < commonForm.formState.workersCount; i++) {
          setTotalJobsCreated(i + 1);

          if (isSplitJobInSeveralOneDay) {
            const jobList = splitJobInOneDay();

            for (const jobReq of jobList) {
              //console.log("**** SPLIT: JOB FORM SUBMIT STATE", jobReq)
              const { paymentIntent } = await createPaymentIntent(jobReq, cardData);

              const clientSecret = paymentIntent.client_secret;

              const paymentResponse = await stripe.confirmCardPayment(clientSecret!);

              await Parse.Cloud.run("register_payment", { jobReq, paymentResponse, isInvoice: false, splitIntoOneDayJobs: true  });
            }
          } else {
            const jobReq = toServerData(commonForm.formState, company?.id || "", totals);
            //console.log("**** NO SPLIT: JOB FORM SUBMIT STATE", jobReq)

            if (commonForm.formState.workersCount != 1) {
              jobReq.job.preferredWorker = null;
            }

            const { paymentIntent } = await createPaymentIntent(jobReq, cardData);

            const clientSecret = paymentIntent.client_secret;

            const paymentResponse = await stripe.confirmCardPayment(clientSecret!);

            await Parse.Cloud.run("register_payment", { jobReq, paymentResponse, isInvoice: false });
          }
        }

        publishingJobsModal.close();
        successModal.open();
      } catch (err) {
        console.log("error", err);
        publishingJobsModal.close();
        alert(_t("critical_error_happened"));
      }
    }
  });

  //RETRIEVE LAT LGN
  const jobFormState = commonForm.formState;
  const addressArgs = [jobFormState.address, jobFormState.postalCode, jobFormState.city, jobFormState.province];

  const getTotalHours = (workers: any) => {
    let total = 0;

    const totalWorkers = !workers ? commonForm.formState.workersCount : workers;

    jobFormState.dates?.map((date) => {
      let durationMultiplier = ".00";
      let durationHour = "";

      if (date.duration) {
        if (date.duration.includes(".15")) durationMultiplier = ".25";
        if (date.duration.includes(".30") || date.duration.includes(".3")) durationMultiplier = ".50";
        if (date.duration.includes(".45")) durationMultiplier = ".75";

        durationHour = date.duration.split(".")[0];
        total += Number(`${durationHour}${durationMultiplier}`);
      } else {
        total += 0;
      }
    });

    setSubTotal(total * totalWorkers * hourlyCost);
    setTotalHours(total * totalWorkers);
  };

  const setStartTime = (selectedDate, currentDate) => {
    jobFormState.dates?.forEach((value, index) => {
      if (value.date.getTime() == currentDate.date.getTime()) {
        jobFormState.dates[index].startTime = dayjs(selectedDate).format("hh:mm a").replace(" pm", "PM").replace(" am", "AM");
      }
    });

    commonForm.setFormValue({
      ...jobFormState,
    });
  };

  const watchProvince = async (province) => {
    jobFormState.province = province;

    commonForm.setFormValue({
      ...jobFormState,
    });

    setTaxRate(await getTaxRate(province));
  };

  const getRates = async () => {
    const minHourlyRate = await getParseConfig("minHourlyRate");
    const markupRate = company?.get("hourlyCostMarkup") || (await getParseConfig("hourlyCostMarkup"));

    setMarkupRate(markupRate);
    setMinimumHourlyRate(minHourlyRate);
    setForkliftCost(await getParseConfig("forkliftCost"));
    setForkliftRate(await getParseConfig("forkliftRate"));

    // calculateHourlyRate(minHourlyRate, markupRate);

    const hourlyRateSchema = Joi.object({
      customHourlyRate: Joi.number().required().min(minHourlyRate),
    });

    const schema = Joi.object().concat(jobSchema).concat(hourlyRateSchema);

    jobSchema = schema;

    setIsRatesFetched(true);
  };

  const calculateHourlyRate = (hourlyRate, markupRate) => {
    if (hourlyRate == 0) {
      jobFormState.customHourlyRate = null;
      setHourlyRate(null);
      return;
    }

    jobFormState.customHourlyRate = hourlyRate;

    commonForm.setFormValue({
      ...jobFormState,
    });

    const markupPercentage = percentageCalc(markupRate, hourlyRate);

    setHourlyRate(hourlyRate);
    setHourlyCost(Number((hourlyRate + markupPercentage).toFixed(2)));

    getTotalHours(null);
  };

  const getJobToDuplicate = async (id) => {
    const fetchedJob = await getJob(id, company);

    const fetchedJobDates = await getJobDates(fetchedJob);

    const duplicateJobDates: any[] = [];

    for (const jobDate of fetchedJobDates) {
      const dateAttr = jobDate.attributes;

      if (moment().isBefore(moment(dateAttr.date))) {
        duplicateJobDates.push(jobDate);
      }
    }

    setTaxRate(await getTaxRate(fetchedJob?.province));

    jobFormState.dates = [];
    jobFormState.forklift = {};

    jobFormState.address = fetchedJob?.address;
    jobFormState.city = fetchedJob?.city;
    jobFormState.province = fetchedJob?.province;
    jobFormState.postalCode = fetchedJob?.postalCode;
    jobFormState.customHourlyRate = fetchedJob?.hourlyRate;

    const translatedJobTask = translateJobTask(fetchedJob?.mainTask);

    jobFormState.mainTask = !jobTasks.includes(translatedJobTask) ? _t("other") : translatedJobTask;
    jobFormState.otherTask = !jobTasks.includes(translatedJobTask) ? translatedJobTask : "";

    jobFormState.liftWeight = `${_t("up_to")} ${fetchedJob?.liftWeight} ${_t("lbs")}`;
    jobFormState.requireWarehouseExperience = fetchedJob?.requireWarehouseExperience ? _t("required") : _t("optional");

    const translatedLanguages = fetchedJob?.languages?.map((lng) => _t(lng.toLowerCase()));
    jobFormState.languages = translatedLanguages?.map((lng) => lng.charAt(0).toUpperCase() + lng.slice(1));

    fetchedJob?.forklifts?.forEach((fklift) => {
      jobFormState.forklift[fklift.id] = true;
    });

    jobFormState.specialInstruction = fetchedJob?.specialInstruction;
    jobFormState.specialInstructionFR = fetchedJob?.specialInstructionFR;
    jobFormState.additionalEmail = fetchedJob?.additionalEmail;
    jobFormState.details = fetchedJob?.details;
    jobFormState.detailsFR = fetchedJob?.detailsFR;

    const prefWorker = fetchedJob?.preferredWorker;

    if (prefWorker) {
      jobFormState.preferredWorker = prefWorker.id;
    }

    duplicateJobDates.sort(function (a, b) {
      return a.attributes.date - b.attributes.date;
    });

    calculateHourlyRate(fetchedJob?.hourlyRate, markupRate);

    const duplicatedDates: any[] = [];

    duplicateJobDates.forEach((jobDate) => {
      if (!moment(jobDate.get("date")).isSameOrBefore(moment(), "day")) {
        duplicatedDates.push(jobDate);
      }
    });

    setJobDuplicateDates(duplicatedDates);

    getTotalHours(null);

    commonForm.setFormValue({
      ...jobFormState,
    });
  };

  const setJobDuplicateDates = (dates) => {
    for (const date of dates) {
      const attr = date.attributes;

      const getDurationInDate = () => {
        const durationSplit = attr.duration.toString().split(".");

        const durationHours = durationSplit[0].toString().length == 1 ? `0${durationSplit[0]}` : durationSplit[0];
        let durationMinutes;

        if (durationSplit.length == 1) {
          durationMinutes = "00";
        } else {
          durationMinutes = durationSplit[1].toString().length == 1 ? `${durationSplit[1]}0` : durationSplit[1];
        }

        return `${durationHours}.${durationMinutes}`;
      };

      const jobDateISO = getJobDateStartDateISO(date);
      const jobDate = new Date(jobDateISO.split("T")[0] + "T08:00:00");

      console.log(jobDate);

      jobFormState.dates.push({
        date: jobDate,
        startTime: attr.startTime,
        duration: getDurationInDate(),
      });
    }
  };

  const setDuration = (selectedDate, currentDate) => {
    jobFormState.dates?.forEach((value, index) => {
      if (value.date.getTime() == currentDate.date.getTime()) {
        jobFormState.dates[index].duration = selectedDate ? timeToNumber(selectedDate) : null;
      }
    });

    getTotalHours(null);

    commonForm.setFormValue({
      ...jobFormState,
    });
  };

  const setDates = (dates) => {
    commonForm.setFormValue({
      ...jobFormState,
      dates: dates
        .sort((a, b) => a - b)
        .map((selectedDate) => {
          let rawDate = new Date(selectedDate);
          let existingData = jobFormState.dates?.find((it) => it.date.getTime() === rawDate.getTime());

          if (existingData) {
            return existingData;
          } else {
            const userTz = momentTz.tz.guess();
            const dateTz = moment(rawDate.getTime()).tz(userTz);

            return {
              date: dateTz.toDate(),
            };
          }
        }),
    });

    getTotalHours(null);
  };

  const removeDate = (date) => {
    const dateIndex = jobFormState.dates?.indexOf(date);
    jobFormState.dates?.splice(dateIndex, 1);

    getTotalHours(null);

    commonForm.setFormValue({
      ...jobFormState,
    });
  };

  const copySetupDates = (date) => {
    jobFormState.dates.forEach((d, index) => {
      if (moment(d.date).isAfter(moment(date.date))) {
        jobFormState.dates[index].startTime = date.startTime;
        jobFormState.dates[index].duration = date.duration;
      }
    });

    getTotalHours(null);

    commonForm.setFormValue({
      ...jobFormState,
    });
  };

  const calculateTaxes = (subTotalFinal: any = null) => {
    return percentageCalc(taxRate, subTotalFinal ? subTotalFinal : subTotal);
  };

  let selectedDates = jobFormState.dates || [];
  let selectedDatesCount = selectedDates.length;

  const invalidCoordinates = commonForm.submitted && !latLng && isLatLngError;
  const addressFilled = addressArgs.length === 4 && addressArgs.every((it) => it);

  const cardOptions = cards.length > 0 ? cards.map((it) => "Visa ending with " + it.last4) : [_t("no_cards_registered")];

  const getStartTimeValue = (startTime) => {
    const split = startTime.split(":");

    if (startTime.includes("PM")) {
      const hour = split[0] == "12" ? "12" : Number(split[0]) + 12;

      startTime = `${hour}:${split[1]}`;
    }

    if (startTime.includes("AM")) {
      const hour = split[0] == "12" ? "00" : split[0];

      startTime = `${hour}:${split[1]}`;
    }

    startTime = startTime.replace("AM", "").replace("PM", "");

    return moment(new Date("2020-01-01T" + startTime));
  };

  const handleFocusedDateChange = (focused, selected) => {
    const selectedDate = new Date(selected);
    const focusedDate = new Date(focused);

    const isFocusedAndSelectedTheSame = selectedDate.toLocaleDateString() == focusedDate.toLocaleDateString();

    if (!focused || !isFocusedAndSelectedTheSame) {
      const dateToRemove = jobFormState.dates?.find((it) => it.date.toLocaleDateString() === selectedDate.toLocaleDateString());
      const dateToRemoveIndex = jobFormState.dates?.indexOf(dateToRemove);

      jobFormState.dates?.splice(dateToRemoveIndex, 1);

      getTotalHours(null);

      commonForm.setFormValue({
        ...jobFormState,
      });
    }
  };

  const currentLanguage = getSaveLanguagePreference()
  const isEnglish = currentLanguage === "en"

  const handleTranslation = ({ translation, key }: { translation: string, key: string }) => {

    const jobFormState = commonForm.formState;

    commonForm.setFormValue({
      ...jobFormState,
      [key]: translation
    });
  }

  return (
    <div>
      <HeaderSubtract>
        <FormControl title={_t("create_a_new_job")} actionElm={null} />

        <div className="l-row l-content jobCreate" style={{ marginTop: "50px" }}>
          <div className="l-desk-8-12">
            <div style={{ maxWidth: "700px" }}>
              {!isPayment && (
                <>
                  <FormSectionTitle>{_t("how_many_workers_do_you_need")}</FormSectionTitle>

                  <div onClick={(e: any) => getTotalHours(e.target.value)} style={{ width: "400px" }}>
                    <Field
                      name="workersCount"
                      label={_t("numbers_of_workers")}
                      type="select"
                      options={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15"]}
                      variant="outlined"
                      style={{ width: "22ch" }}
                      {...commonForm.genericFieldProps}
                    />
                  </div>

                  <div className="l-text-discreet" style={{ bottom: 5, position: "relative" }}>
                    {_t("if_you_want_rehire_past_worker_keep_above")}
                  </div>

                  {commonForm.formState.workersCount == 1 ? (
                    <>
                      <FormSectionTitle style={{ marginTop: "50px" }}>{_t("want_to_rehire_a_past_worker")}</FormSectionTitle>

                      <div onClick={(e: any) => console.log(e.target.value)} style={{ width: "410px" }}>
                        <CustomField
                          name="preferredWorker"
                          label={_t("rehire_past_worker_text")}
                          type="select"
                          selectValueToDisplay={(v) => v.name}
                          options={recentWorkers}
                          variant="outlined"
                          style={{ width: "48ch" }}
                          {...commonForm.genericFieldProps}
                        />
                      </div>
                    </>
                  ) : null}

                  <FormSectionTitle style={{ marginTop: "50px" }}>{_t("when_is_the_job")}</FormSectionTitle>

                  <div>
                    <div
                      style={{
                        display: "inline-block",
                        border: commonForm.submitted && !jobFormState.dates?.length ? "1px solid red" : undefined,
                      }}
                    >
                      <Calendar
                        months={calendarLocalizedMonths()}
                        weekDays={calendarLocalizedWeekDays()}
                        onFocusedDateChange={(dateFocused: any, dateClicked: any) => {
                          handleFocusedDateChange(dateFocused, dateClicked);
                        }}
                        multiple
                        minDate={dayjs().add(1, "day").startOf("day").toDate()}
                        value={selectedDates.map((v) => v.date)}
                        onChange={setDates}
                      />
                    </div>
                  </div>

                  <div style={{ marginTop: "20px" }}>
                    {selectedDatesCount > 0 ? (
                      <div>
                        <strong>
                          {selectedDatesCount} {_t("selected_dates")}
                        </strong>
                        <div style={{ color: "#A8A8A8", marginTop: "10px" }}>
                          {_t("please_fill_out_the_starting_time")}{" "}
                          <a
                            style={{ color: "#325E8C", textDecoration: "underline" }}
                            href="https://www.cnesst.gouv.qc.ca/en/working-conditions/work-schedule-and-termination-employment/work-schedule/presence-work-breaks-and-weekly-rest-period"
                            target="_blank"
                          >
                            {_t("cnesst_rules")}
                          </a>
                        </div>
                      </div>
                    ) : (
                      <span className="l-text-discreet">
                        {selectedDatesCount} {_t("selected_dates")}
                      </span>
                    )}
                  </div>

                  <div
                    style={{
                      width: "100%",
                      fontSize: "13px",
                      lineHeight: "19px",
                    }}
                  >
                    <div style={{ marginBottom: "7%" }}>
                      {selectedDates
                        .sort((a, b) => a - b)
                        .map((it, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="l-flex-align-center"
                                style={{
                                  marginTop: "5%",
                                  justifyContent: "space-between",
                                  border: commonForm.submitted && (!it.startTime || !it.date) ? "1px solid red" : undefined,
                                }}
                              >
                                <CloseIconImg className="l-clickable" style={{ display: "inline-block" }} onClick={() => removeDate(it)} />{" "}
                                <strong style={{ display: "inline-block", width: "70px", textTransform: "capitalize" }}>{formatDateToLocale(it.date)}</strong>
                                <span className="l-text-discreet">{_t("starts_at")}</span>
                                <TimePicker
                                  defaultOpenValue={moment("2020-01-01T01:00:00")}
                                  value={it.startTime ? getStartTimeValue(it.startTime) : undefined}
                                  onChange={(date) => setStartTime(date, it)}
                                  use12Hours={true}
                                  showSecond={false}
                                  minuteStep={15}
                                  allowEmpty={false}
                                />
                                <span className="l-text-discreet">{_t("duration_hrs")}</span>
                                <div>
                                  <TimePicker
                                    defaultOpenValue={moment("2020-01-01T08:00:00")}
                                    value={it.duration ? moment("2020-01-01T" + numberToTime(it.duration)) : undefined}
                                    onChange={(date) => setDuration(date, it)}
                                    showSecond={false}
                                    minuteStep={15}
                                    hideDisabledOptions
                                    allowEmpty={false}
                                    disabledHours={() => [0, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24]}
                                  />
                                </div>
                                <CopySetupIcon
                                  onClick={() => copySetupDates(it)}
                                  style={{
                                    visibility: index != selectedDates.length - 1 && it.date && it.duration ? "visible" : "hidden",
                                    cursor: "pointer",
                                  }}
                                />
                                <div
                                  style={{
                                    visibility: index != selectedDates.length - 1 && it.date && it.duration ? "visible" : "hidden",
                                    width: "138px",
                                    height: "auto",
                                    backgroundColor: "#F2F2F2",
                                    borderRadius: "7px",
                                    padding: "10px",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "#325E8C",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {_t("copy_this_setup_for_all_dates")}
                                  </span>
                                </div>
                              </div>
                            </>
                          );
                        })}
                    </div>

                    <Field
                      className="l-full-width"
                      name="jobOfferOption"
                      label={_t("question_split_job")}
                      options={jobOfferOptions}
                      optionLabels={jobOfferOptionsLabels}
                      type="radioWithLabels"
                      {...commonForm.genericFieldProps}
                    />
                  </div>

                  <div style={{ width: "400px" }}>
                    <FormSectionTitle style={{ marginTop: "50px" }}>{_t("where_is_the_job")}</FormSectionTitle>

                    <div style={{ border: invalidCoordinates && addressFilled ? "1px solid red" : undefined }}>
                      <Field className="l-full-width" name="address" label={_t("address")} variant="outlined" {...commonForm.genericFieldProps} />

                      <Field className="l-full-width" name="city" label={_t("city")} variant="outlined" {...commonForm.genericFieldProps} />
                      <Field className="l-full-width" name="postalCode" label={_t("postal_code")} variant="outlined" {...commonForm.genericFieldProps} />
                      <Field
                        name="province"
                        label={_t("province")}
                        type="select"
                        options={listProvinces}
                        className="l-full-width"
                        variant="outlined"
                        {...commonForm.genericFieldProps}
                        {...{ onChange: (_, value) => watchProvince(value) }}
                      />
                    </div>

                    {invalidCoordinates && addressFilled ? (
                      <div style={{ margin: "10px 0", color: "#f44336", fontSize: "12px" }}>
                        <div>{_t("we_could_not_find_that_address")}</div>
                      </div>
                    ) : null}

                    <FormSectionTitle style={{ marginTop: "50px" }}>{_t("what_is_the_job")}</FormSectionTitle>

                    <Field
                      max={30}
                      className="l-full-width"
                      name="customHourlyRate"
                      label={_t("hourly_rate_offered_to_workers").replace("[hourlyRate]", formatMonetary(minimumHourlyRate))}
                      variant="outlined"
                      style={{ width: "22ch", marginTop: 10 }}
                      type="number"
                      preventErrMsg={true}
                      {...commonForm.genericFieldProps}
                      {...{
                        onChange: (_, value) => {
                          calculateHourlyRate(Number(value), markupRate);
                        },
                      }}
                    />

                    <div className="l-text-discreet" style={{ marginTop: 11, marginBottom: 25 }}>
                      {_t("hourly_rate_service_fee").replace("[serviceFee]", markupRate.toString())}
                    </div>

                    <Field className="l-full-width" name="mainTask" label={_t("main_task")} options={jobTasks} type="radio" {...commonForm.genericFieldProps} />

                    {commonForm.formState.mainTask == _t("other") ? (
                      <Field
                        max={30}
                        className="l-full-width"
                        name="otherTask"
                        label={_t("please_specify_the_main_task")}
                        variant="outlined"
                        {...commonForm.genericFieldProps}
                      />
                    ) : null}

                    <div>
                      <strong>{_t("please_provide_more_details_about_this_job_optional_label")}</strong>
                    </div>

                    <Field
                      actionButton={
                        <TranslateButton
                          style={{
                            padding: '2px 12px',
                            fontSize: '14px',
                            lineHeight: '20px'
                          }}
                          textToTranslate={isEnglish ? commonForm.formState.details : commonForm.formState.detailsFR}
                          onTranslate={(translation) => handleTranslation({
                            translation,
                            key: isEnglish ? 'detailsFR' : 'details'
                          })}
                          sourceLang={isEnglish ? "en" : "fr"}
                          targetLang={isEnglish ? "fr" : "en"}
                          valid
                        >{isEnglish ? EN.translate_from_english : FR.translate_from_french}</TranslateButton>}
                      label={_t("english_version")}
                      name="details"
                      className="l-full-width"
                      rows={5}
                      placeholder={EN.please_provide_more_details_about_this_job_optional_placeholder}
                      type="textarea"
                      variant="outlined"
                      {...commonForm.genericFieldProps}
                    />

                    <Field
                      label={_t("french_version")}
                      name="detailsFR"
                      className="l-full-width"
                      rows={5}
                      placeholder={FR.please_provide_more_details_about_this_job_optional_placeholder}
                      // placeholder={_t("please_provide_more_details_about_this_job_optional_placeholder")}
                      type="textarea"
                      variant="outlined"
                      {...commonForm.genericFieldProps}
                    />

                    <Field
                      className="l-full-width"
                      name="liftWeight"
                      label={_t("weight_to_lift")}
                      options={[`${_t("up_to")} 10 ${_t("lbs")}`, `${_t("up_to")} 25 ${_t("lbs")}`, `${_t("up_to")} 50 ${_t("lbs")}`]}
                      type="radio"
                      {...commonForm.genericFieldProps}
                    />

                    <FormSectionTitle style={{ marginTop: "50px" }}>{_t("which_competencies_are_requested")}</FormSectionTitle>

                    <div>
                      <FieldLabel label={_t("forklift_experience")} />
                      <div onChange={() => handleForkliftOption()} style={{ margin: "15px 0" }}>
                        {forklifts.forklifts.map((it, index) => {
                          return (
                            <div key={index} style={{ marginLeft: "-10px" }}>
                              <Field
                                type="checkbox"
                                name={`forklift.${it.id}`}
                                {...commonForm.genericFieldProps}
                                label={
                                  <>
                                    <div className="l-flex-align-center">
                                      {it.image && (
                                        <img
                                          src={it.image?.url?.()}
                                          style={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "5px",
                                            overflow: "hidden",
                                            marginRight: "15px",
                                            display: "inline-block",
                                          }}
                                        />
                                      )}
                                      {translateForkliftName(it.name)}
                                    </div>
                                  </>
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>

                  <FieldLabel label={_t("required_spoken_languages")} />
                  <Field
                    className="l-full-width"
                    name="languages"
                    checkbodNoLeftMargin
                    options={[_t("french"), _t("english")]}
                    type="checkbox"
                    {...commonForm.genericFieldProps}
                  />

                  <FormSectionTitle style={{ marginTop: "50px" }}>{_t("special_instructions_for_worker")}</FormSectionTitle>

                  <div>
                    <strong>{_t("this_will_only_be_seen_by_the_worker")}</strong>
                  </div>

                  <Field
                    actionButton={
                      <TranslateButton
                        style={{
                          padding: '2px 12px',
                          fontSize: '14px',
                          lineHeight: '20px'
                        }}
                        textToTranslate={isEnglish ? commonForm.formState.specialInstruction : commonForm.formState.specialInstructionFR}
                        onTranslate={(translation) => handleTranslation({
                          translation,
                          key: isEnglish ? 'specialInstructionFR' : 'specialInstruction'
                        })}
                        valid
                        sourceLang={isEnglish ? "en" : "fr"}
                        targetLang={isEnglish ? "fr" : "en"}
                      >{isEnglish ? EN.translate_from_english : FR.translate_from_french}</TranslateButton>}
                    label={_t("english_version")}
                    name="specialInstruction"
                    className="l-full-width"
                    rows={5}
                    placeholder={EN.please_provide_instructions_and_information}
                    type="textarea"
                    variant="outlined"
                    {...commonForm.genericFieldProps}
                  />

                  {/* <Field
                    label="French version"
                    name="specialInstructionFR"
                    className="l-full-width"
                    rows={5}
                    placeholder={_t("please_provide_instructions_and_information")}
                    type="textarea"
                    variant="outlined"
                    {...commonForm.genericFieldProps}
                  /> */}
                  <Field
                    label={_t("french_version")}
                    name="specialInstructionFR"
                    className="l-full-width"
                    rows={5}
                    placeholder={FR.please_provide_instructions_and_information}
                    type="textarea"
                    variant="outlined"
                    {...commonForm.genericFieldProps}
                  />

                  <FormSectionTitle style={{ marginTop: "50px" }}>{_t("additional_email_notify_title")}</FormSectionTitle>

                  <div>
                    <strong>{_t("email_address")}</strong>
                  </div>

                  <Field
                    name="additionalEmail"
                    type="email"
                    className="l-full-width"
                    placeholder={_t("email_address")}
                    variant="outlined"
                    {...commonForm.genericFieldProps}
                  />
                </>
              )}

              {isPayment && (
                <>
                  <FormSectionTitle>{_t("payment_method")}</FormSectionTitle>

                  <div className="l-text-discreet">{_t("select_a_payment_method_to_publish_the_job")}</div>

                  <Field name="paymentOpt" type="radio" {...paymentForm.genericFieldProps}>
                    {cards.length > 0 ? (
                      <>
                        <FormControlLabel
                          control={<Radio value="1" />}
                          checked={paymentForm.formState.paymentOpt === "1"}
                          label={_t("use_a_credit_card_linked_to_my_account")}
                        />

                        <Field
                          className="l-full-width"
                          name="selectedCard"
                          type="select"
                          options={cardOptions}
                          variant="outlined"
                          containerStyle={{ marginTop: "20px" }}
                          {...paymentForm.genericFieldProps}
                        />
                      </>
                    ) : (
                      <div style={{ backgroundColor: "#F9F9F9", width: "405px", height: "93px", marginLeft: "-26px", paddingLeft: "25px" }}>
                        <FormControlLabel
                          control={<Radio value="1" disabled={true} />}
                          checked={paymentForm.formState.paymentOpt === "1"}
                          label={_t("use_a_credit_card_linked_to_my_account")}
                        />
                        <br></br>
                        <span style={{ marginLeft: "5px" }}>{_t("no_credit_card_linked_to_my_account")}</span>
                      </div>
                    )}

                    <FormControlLabel
                      control={<Radio value="2" />}
                      checked={paymentForm.formState.paymentOpt === "2"}
                      label={_t("add_a_credit_card")}
                      style={{ marginTop: "10px" }}
                    />
                  </Field>

                  <CreditCardForm key="same1" genericFieldProps={paymentForm.genericFieldProps} />
                </>
              )}
            </div>
          </div>

          <div className="l-desk-4-12 paymentSummary">
            <div className="t-surface-card" style={{ padding: "15px" }}>
              <strong style={{ fontSize: "18px", marginBottom: "30px", display: "block", color: "#325E8C" }}>{_t("job")}</strong>

              <div className="l-flex-between" style={{ margin: "10px 0" }}>
                <div style={{ fontWeight: 500 }}>
                  {commonForm.formState.workersCount} {_t("workers")} x {(totalHours / commonForm.formState.workersCount).toFixed(2)} hrs
                </div>
                <div>{totalHours.toFixed(2)} hrs</div>
              </div>

              <div className="l-flex-between" style={{ margin: "10px 0" }}>
                <div style={{ fontWeight: 500 }}>{_t("hourly_cost")}</div>
                <div>${hourlyCost}/h</div>
              </div>
              <span style={{ fontSize: "12px", bottom: 10, position: "relative", fontWeight: 400 }}>
                {_t("including_x_markup_fee").replace("[serviceFee]", markupRate.toString())}
              </span>

              <hr style={{ margin: "15px 0" }} />

              <div className="l-flex-between" style={{ margin: "10px 0" }}>
                <div style={{ fontWeight: 500 }}>{_t("subtotal")}</div>
                <div>${subTotal.toFixed(2)}</div>
              </div>

              <div className="l-flex-between" style={{ margin: "10px 0" }}>
                <div style={{ fontWeight: 500 }}>{_t("taxes")}</div>
                <div>${calculateTaxes().toFixed(2)}</div>
              </div>

              <hr style={{ margin: "15px 0" }} />

              <div className="l-flex-between" style={{ margin: "20px 0" }}>
                <strong style={{ fontSize: "18px", display: "block", color: "#325E8C" }}>{_t("total")}</strong>
                <div>${(subTotal + calculateTaxes()).toFixed(2)}</div>
              </div>

              {!isPayment && (
                <Button className="l-full-width" valid={true} onClick={commonForm.onSubmit}>
                  {_t("continue")}
                </Button>
              )}
              {isPayment && (
                <Button className="l-full-width" valid={true} onClick={() => paymentModal.open()}>
                  {_t("publish_the_job")}
                </Button>
              )}

              {commonForm.formState.workersCount > 1 && (
                <div className="l-text-discreet" style={{ marginTop: "12px", fontSize: "15px" }}>
                  {_t("as_you_selected_several_workers")}
                </div>
              )}
            </div>
          </div>
        </div>
      </HeaderSubtract>

      <Modal
        title={_t("please_check_again")}
        controlRight={
          <div className="l-clickable" style={{ color: "#325E8C" }} onClick={recheckModal.close}>
            <strong>OK</strong>
          </div>
        }
        {...recheckModal.modalProps}
      >
        {_t("some_information_highlighted_in_red_are_missing")}
      </Modal>

      {/* !isPayment */}

      <Modal title="Loading" {...loadingTaxesModal.modalProps}>
        {_t("please_wait")}
      </Modal>

      <Modal
        title={_t("publish_the_job_question")}
        controlLeft={
          <div onClick={() => paymentModal.close()} className="l-clickable" style={{ color: "#325E8C" }}>
            <strong>{_t("no")}</strong>
          </div>
        }
        controlRight={
          <div onClick={paymentForm.onSubmit} className="l-clickable" style={{ color: "#325E8C" }}>
            <strong>{_t("yes")}</strong>
          </div>
        }
        {...paymentModal.modalProps}
      >
        {_t("workers_will_be_notified_payment")}
      </Modal>

      <Modal
        title={_t("publish_the_job_question")}
        controlLeft={
          <div onClick={() => paymentInvoiceModal.close()} className="l-clickable" style={{ color: "#325E8C" }}>
            <strong>{_t("no")}</strong>
          </div>
        }
        controlRight={
          <div onClick={() => doInvoicePayment()} className="l-clickable" style={{ color: "#325E8C" }}>
            <strong>{_t("yes")}</strong>
          </div>
        }
        {...paymentInvoiceModal.modalProps}
      >
        {_t("workers_will_be_notified_invoice")}
      </Modal>

      <Modal
        title={_t("job_published")}
        controlRight={
          <div onClick={() => navigate(routes.root)} className="l-clickable" style={{ color: "#325E8C" }}>
            <strong>OK</strong>
          </div>
        }
        {...successModal.modalProps}
      >
        {_t("you_will_get_notified_when_a_worker_takes_the_job")}
      </Modal>

      <Modal title="Publishing jobs" {...publishingJobsModal.modalProps}>
        {_t("please_wait_we_are_processing_your_job")} {totalJobsCreated}/{commonForm.formState.workersCount}...
      </Modal>

      <Modal title="Loading job..." {...loadingJob.modalProps}>
        {_t("please_wait_loading_your_job")}
      </Modal>
    </div>
  );
}
