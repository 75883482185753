import moment from "moment";
import momentTz from "moment-timezone";

export function getJobDateStartDateISO(jobDate: any): string {
  if (!jobDate) return new Date().toISOString();

  const jobDateAttr = jobDate.attributes;

  const userTz = momentTz.tz.guess();
  const dateTz = moment(jobDateAttr.date.getTime()).tz(userTz);

  return dateTz.format();
}

export function getJobDateStartDateTime(jobDate: any, addDuration: boolean = false) {
  if (!jobDate) return new Date();

  const jobDateAttr = jobDate.attributes;
  const jobDateArray = jobDateAttr.date.toISOString().split("T");
  const jobTime = jobDateAttr.startTime.replace("AM", "").replace("PM", "");

  let startTime = jobTime;

  if (jobDateAttr.startTime.includes("PM")) {
    const startTimeArray = jobTime.split(":");

    let startTimeHour = parseInt(startTimeArray[0]);

    if (startTimeHour < 12) {
      startTimeHour += 12;

      startTime = `${startTimeHour}:${startTimeArray[1]}`;
    }
  }

  const jobDateObj = new Date(jobDateArray[0] + "T" + startTime);

  const userTz = momentTz.tz.guess();
  const dateTz = moment(jobDateObj.getTime()).tz(userTz);

  if (addDuration) {
    dateTz.add(jobDateAttr.duration, "hours");
  }

  console.log("startTime", startTime);
  console.log("startDateTime", dateTz.toDate());

  return dateTz.toDate();
}

export function getNowDateWithTz() {
  return moment().tz(momentTz.tz.guess());
}
